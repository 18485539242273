import React from 'react';
import bg from '../../assets/background.webp'

const ContactUs = () => {
  return (
    <div style={{padding: 20, backgroundImage: `url(${bg})`, color: "white", height: "100vh"}}>
      <h2>Contact Us</h2>
      <p>Jim Aquarium</p>
      <address>
        2 Valluvar Colony, Thirumal Nagar, Tirunelveli-627007, Tamil Nadu, India
      </address>
      <p>
        <a href="https://wa.me/7904426625">Contact us on Whatsapp: 7904426625</a>
      </p>
    </div>
  );
};

export default ContactUs;
