import React, { useState } from "react";

const ImageUploader = () => {
  const [base64Image, setBase64Image] = useState(""); // State to store the base64 image

  const handleImageUpload = (event) => {
    const file = event.target.files[0]; // Get the uploaded file
    const reader = new FileReader();

    reader.onload = () => {
      const base64String = reader.result; // Extract base64 string
      setBase64Image(base64String); // Set the state with the base64 string
    };

    reader.readAsDataURL(file); // Read the file as data URL
  };

  return (
    <div>
      <input
        type="file"
        accept="image/*"
        onChange={handleImageUpload}
      />
      {base64Image && (
        <div>
          <h3>Base64 Image:</h3>
          <textarea
            rows="5"
            cols="50"
            value={base64Image}
            readOnly
          />
        </div>
      )}
    </div>
  );
};

export default ImageUploader;