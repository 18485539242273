import { useContext, useEffect, useState } from "react";
import { LayoutContext } from "../ShopLayout";
import { useSpring, animated } from "@react-spring/web";
import { CartCard } from "../../Shared/CartCard";
import { colors, dummyCart } from "../../Shared/Constants";
import "./CartContent.css";
import { getProductsAndTotal } from "../../Utils/appUtils";
export function CartContent() {
  const { width, cart, total, setTotal, products, setCart } = useContext(LayoutContext);
  
  const springs = useSpring({
    from: { x: -50 },
    to: { x: 0 },
  });

  // const [listProducts, setListProducts] = useState(dummyProducts.products);

  // useEffect(() => {
  //   console.log(dummyProducts.products.filter((v) => v.categories.includes(selectedCategory)));
  //   setListProducts(dummyProducts.products.filter((v) => v.categories.includes(selectedCategory)))

  // }, [selectedCategory])

  useEffect(() => {

    let productIds = products.map(v => v.productId);
    let localCart = cart.filter(c => productIds.includes(c.productId));
    setCart(localCart);
  }, []);

  useEffect(() => {

    setTotal(getProductsAndTotal(cart, products, "no").total);
  }, [cart]);

  return (
    <>
      <animated.div
        className={"cartContent-root"}
        style={{
          // backgroundColor: "red",
          width: width > 570 ? "60vw" : "90vw",
          display: "inline-flex",
          marginLeft: "5vw",
          marginTop: 30,
          height: width > 570 ? "70vh" : "40vh",
          position: width > 570 ? "fixed" : null,
          ...springs,
        }}
      >
        <CartCard items={cart} />

        
      </animated.div>
      {total !== 0 && (
        <>
        <div style={{ marginTop: 20, textAlign: "end", paddingRight: 25}}>
            <span className="total_content_primary" style={{color: colors.primary}}>{`Items in cart: ₹${total}`}</span>
            
          </div>
          <div style={{ textAlign: "end", paddingRight: 20}}>
          <span className="total_content_secondary" style={{color: colors.primary}}>{`*Shipping costs are calculated at checkout`}</span>
          </div>
          
        </>
          
        )}
    </>
  );
}
