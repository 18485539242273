import { ProductCard } from "../../Shared/ProductCard";
import "./AllProducts.css";
import { useContext, useState } from "react";
import { LayoutContext } from "../ShopLayout";
import { useSpring, animated } from "@react-spring/web";
import ProductAddModal from "../Admin/ProductAddModal";
import { CategoryButtonSm } from "../../Shared/CategoryButtonSm";
export function AllProducts() {
  const {
    width,
    selectedCategory,
    setSubPage,
    setSidePage,
    setSelectedProduct,
    products,
    loadingCache
  } = useContext(LayoutContext);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [selectProduct, setSelectProduct] = useState({
    name: "",
    price: "",
    desc: "",
    size: "",
    categories: "",
    stock: "",
    img: "",
    box: "no"
  });
  const [productId, setProductId] = useState("1")
  const springs = useSpring({
    from: { x: -50 },
    to: { x: 0 },
  });
  let userDetails = localStorage.getItem("user");
  if (userDetails) {
    userDetails = JSON.parse(userDetails);
  }

  // const [listProducts, setListProducts] = useState(dummyProducts.products);

  // useEffect(() => {
  //   console.log(dummyProducts.products.filter((v) => v.categories.includes(selectedCategory)));
  //   setListProducts(dummyProducts.products.filter((v) => v.categories.includes(selectedCategory)))

  // }, [selectedCategory])

  return (
    <>
      {(userDetails?.email === "bkevin1999@gmail.com" || userDetails?.email === "ranjithkingjimson7788@gmail.com" )&& (
        <div style={{ marginLeft: 30, marginTop: 20 }}>
          <CategoryButtonSm
            action={() => {
              setProductId("1");
              setSelectProduct({
                name: "",
                price: "",
                desc: "",
                size: "",
                categories: "",
                stock: "",
                img: "",
                defaultWeight: "",
                bunch: "",
                box: "no"
              })
              setIsOpenModal(true);
            }}
            content={"Add Product"}
            mode={"active"}
          />
        </div>
      )}
      
      {!loadingCache && <animated.div
        className={"productContent"}
        
        style={{
          display: "inline-block",
          textAlign: "center",  
          marginTop: 30,
          height: width > 570 ? "80vh" : "50vh",
          position: width > 570 ? "fixed" : null,
          width: "100%",
          ...springs,
        }}
      >
        {/* <Grid container> */}
          {products.map((v, _) => {
            return (
              <span
              key={`${v.productId}-${v.name}`}
                onClick={() => {
                  // console.log(v.productId.toString());
                  if(userDetails?.email === "bkevin1999@gmail.com" || userDetails?.email === "ranjithkingjimson7788@gmail.com") {
                    setProductId(v.productId.toString());
                    setSelectProduct({
                      productId: v.productId.toString(),
                      name: v.name,
                      price: v.price.toString(),
                      desc: v.desc,
                      size: v.size.toString(),
                      categories: v.categories,
                      stock: v.stock,
                      img: v.img,
                      defaultWeight: v.defaultWeight,
                      bunch: v.bunch,
                      box: v.box
                    })
                    // setTimeout(() => {
                      setIsOpenModal(true);
                    // }, 500)
                    
                  } else {
                    setSelectedProduct(v);
                    setSubPage("product");
                    setSidePage("Product");
                  }
                  
                  
                }}
              >
 <ProductCard
                  name={v.name}
                  size={v.size}
                  selectedCategory={selectedCategory}
                  productCategory={v.categories}
                  img={v.img}
                  price={v.price}
                  theme={v.theme}
                  defaultWeight={v.defaultWeight}
                  bunch={v.bunch}
                  stock={v.stock}
                />
              </span>
           
                
              // </Grid>
            );
          })}
        {/* </Grid> */}
        {isOpenModal && <ProductAddModal
          open={true}
          handleClose={() => {
            // setSidePage("Admin");
            // setSubPage("allProducts")
            setIsOpenModal(false);
          }}
          initialState={selectProduct}
          id={productId}
        />}
        
      </animated.div>}
      
    </>
  );
}
