import { LayoutContext, createOrder } from "../Shop/ShopLayout";
import { useContext, useState } from "react";
import { List, ListItem, ListItemText } from "@mui/material";
import { colors } from "./Constants";
import { PayButton } from "./PayButton";
import ReplayIcon from "@mui/icons-material/Replay";
import { addToCartAPI } from "../Shop/ProductDetails/ProductContent";
import OrderUpdateModal from "../Shop/Admin/OrderUpdateModal";
export function OrdersCardAdmin({ items, isOpenModal, setIsOpenModal, setSelectedOrder }) {
  const {
    width,
    address,
    selectedProduct,
    setAddress,
    setCart,
    setOpenSnackbar,
    setOpenSnackbarContent,
    setOrderId,
    setOrders,
    setSidePage,
    setSnackSeverity,
    setSubPage,
    orders,
    products,
    cart,
  } = useContext(LayoutContext);
  const userDetails = JSON.parse(localStorage.getItem("user"));
  const [saving, setSaving] = useState(false);

  const formatDate = (d) => {
    const date = new Date(d);
    const formattedDate = date.toLocaleDateString('en-GB', {
      day: 'numeric', month: 'short'
    }).replace(/ /g, '-');
    return (formattedDate);
  }
 const sortAlg = (data) => {
  return data.sort((a, b) => {
    // Parse the ISO-8601 formatted date strings
    const dateA = new Date(a.updated);
    const dateB = new Date(b.updated);
  
    // Compare the dates
    return dateB - dateA;
  });
 }

 const sortedOrder = sortAlg(items);


  return (
    <>
      <List className="cartContent cartContent-root" sx={{ width: "100%" }}>
        {sortedOrder.map((v, i) => {
          return (
            <>
              <ListItem
                alignItems="flex-start"
                style={{ backgroundColor: v.default && colors.secondary }}
                onClick={() => { setSelectedOrder(v) }}
              >
                <ListItemText
                  primary={
                    <></>
                    // <span
                    //   style={{ color: colors.primary }}
                    //   className="orders_content_title"
                    // >{`${v.status}`}</span>
                  }
                  secondary={
                    <>
                      <div>
                        <span
                          style={{ color: colors.primary }}
                          className="orders_content_title"

                        >{`#${v.orderId}`}</span>
                      </div>
                      <div>
                        <span className="orders_content_secondary">{`${formatDate(v.updated)}`}</span>
                      </div>
                      <div>
                        <span className="orders_content_secondary">{`Status: ${v.status}`}</span>
                      </div>
                      <div>
                        <span className="orders_content_secondary">{`Items:`}</span>
                      </div>

                      {v.products.map((product, i) => (
                        <div style={{ marginLeft: 10 }}>
                          <span className="orders_content_secondary">{`${product.name} x ${product.quantity}`}</span>
                        </div>
                      ))}
                      {/* <div>
                        <span className="orders_content_secondary">{` Shipping: ₹${v.shipping} `}</span>
                        {v.box && (
                          <span className="orders_content_secondary">{`(Box Included)`}</span>
                        )}
                      </div> */}
                      <div
                        style={{
                          height: 1,
                          width: "50%",
                          backgroundColor: "grey",
                          marginTop: 5,
                          marginBottom: 5,
                          marginLeft: 10,
                        }}
                      >
                        {/* <span className="orders_content_secondary_total">{`Amount: ₹${parseFloat(v.total) + parseFloat(v.shipping)}`}</span> */}
                      </div>
                      <div style={{ marginLeft: 10 }}>
                        <span className="orders_content_secondary_total">{`Amount: ₹${parseFloat(v.total) + parseFloat(v.shipping)
                          }`}</span>
                      </div>
                      <div>
                        <span
                          style={{
                            color:
                              v.paymentStatus === "Failed"
                                ? colors.warning
                                : colors.primary,
                          }}
                          className="orders_content_secondary"
                        >{`Payment Status - ${v.paymentStatus}`}</span>
                      </div>
                    </>
                  }
                />
              </ListItem>
              {/* <Divider variant="inset" component="li" /> */}
            </>
          );
        })}
        <>
          <ListItem>
            {width > 570 && (
              <div
                style={{
                  // backgroundColor: "red",
                  marginTop: 10,
                  // marginLeft: width < 570 && -20,
                  width: "100vw",
                  textAlign: "center",
                }}
              ></div>
            )}
          </ListItem>
        </>
      </List>
    </>
  );
}
