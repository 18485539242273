import React, { useState, useEffect, useContext } from "react";
import "./OrdersContent.css";
import { colors, paymentOptions } from "../../Shared/Constants";
import { LayoutContext, ordersAPI } from "../ShopLayout";
import OrdersLoadingUPI from './OrdersLoadingUPI'
import makeApiRequest from "../../Utils/api";

export const getPaymentStatus = async ({
  orderId,
  setSnackSeverity,
  setOpenSnackbar,
  setOpenSnackbarContent,
  setIsProcessing,
  setSidePage,
  setSubPage,
  setOrders,
}) => {
  try {
    let res = await makeApiRequest(
      "POST",
      { order_id: orderId },
      "/orders/status",
      setSnackSeverity,
      setOpenSnackbar,
      setOpenSnackbarContent,
      false
    );

    if (res?.status === 200) {
      const orderStatus = res?.data[0]?.payment_status;
      if (orderStatus === "SUCCESS") {
        try {
          let res = await makeApiRequest(
            "POST",
            { orderId: orderId, paymentStatus: "Paid" },
            "/orders/update",
            setSnackSeverity,
            setOpenSnackbar,
            setOpenSnackbarContent,
            false
          );

          if (res?.status === 200) {
            await ordersAPI(
              setSnackSeverity,
              setOpenSnackbar,
              setOpenSnackbarContent,
              setOrders
            );
            setIsProcessing(false);
            localStorage.removeItem('orderId');
            setSidePage("Orders");
            setSubPage("orders");
          }
        } catch (e) {
          console.log(e);
        }
      } else if (orderStatus === "FAILED") {
        try {
          let res = await makeApiRequest(
            "POST",
            { orderId: orderId, paymentStatus: "Failed" },
            "/orders/update",
            setSnackSeverity,
            setOpenSnackbar,
            setOpenSnackbarContent,
            false
          );

          if (res?.status === 200) {
            await ordersAPI(
              setSnackSeverity,
              setOpenSnackbar,
              setOpenSnackbarContent,
              setOrders
            );
            setIsProcessing(false);
            localStorage.removeItem('orderId');
            setSidePage("Orders");
            setSubPage("orders");
          }
        } catch (e) {
          console.log(e);
        }
      }
    }
  } catch (e) {
    console.log(e);
  }
};

export const OrdersLoading = () => {
  const [isProcessing, setIsProcessing] = useState(true);
  const {
    setSnackSeverity,
    setOpenSnackbar,
    setOpenSnackbarContent,
    setSidePage,
    setSubPage,
    setOrders,
  } = useContext(LayoutContext);
  const orderId = localStorage.getItem("orderId");
  const links = localStorage.getItem("link");
  let isCashFree =  paymentOptions.cashfree;

  useEffect(() => {
    let timeout;
    if (orderId && isCashFree) {
      timeout = setInterval(() => {
        getPaymentStatus({
          orderId: orderId,
          setSnackSeverity: setSnackSeverity,
          setIsProcessing: setIsProcessing,
          setOpenSnackbar: setOpenSnackbar,
          setOpenSnackbarContent: setOpenSnackbarContent,
          setSidePage: setSidePage,
          setSubPage: setSubPage,
          setOrders: setOrders,
        });
      }, 2000);
    }
    // Simulate processing time (adjust as needed)

    return () => {
      if (orderId) clearInterval(timeout);
    };
  }, []);

  return (
    isCashFree ?
    <div className="processing-container">
  
        <h1 style={{ color: colors.primary }} className="processing-title">
        Processing Payment...
      </h1>
      <div class="container-animation-loading">
        <div class="ball"></div>
        <div class="ball"></div>
        <div class="ball"></div>
        <div class="ball"></div>
        <div class="ball"></div>
        <div class="ball"></div>
        <div class="ball"></div>
        <div style={{ textAlign: "center", marginTop: 20, color: "#b5dc74" }}>
          {isProcessing && (
            <span style={{ color: colors.warning }}>
               {`Please wait, your payment is being processed.`}
              
            </span>
          )}
          <div>
          <span onClick={() => {
            localStorage.removeItem('orderId');
            setSidePage("Orders");
            setSubPage("orders");
          }} style={{ color: colors.primary, textDecoration: "underline", marginTop: 10, cursor: "pointer" }}>
            {'Cancel and go back'}
              
            </span>
          </div>
          
        </div>
      </div>

          

      
    </div> : <>
    <OrdersLoadingUPI orderId={orderId} links={links} />
    </>
  );
};
