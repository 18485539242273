import { LayoutContext, createOrder } from "../Shop/ShopLayout";
import { useContext, useState } from "react";
import {
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { colors } from "./Constants";
import { PayButton } from "./PayButton";
import ReplayIcon from '@mui/icons-material/Replay';
import { addToCartAPI } from "../Shop/ProductDetails/ProductContent";
export function OrdersCard({ items }) {
  const { width, address,
    selectedProduct,
    setAddress,
    setCart,
    setOpenSnackbar,
    setOpenSnackbarContent,
    setOrderId,
    setOrders,
    setSidePage,
    setSnackSeverity,
    setSubPage,
    orders,
    products,
    cart } = useContext(LayoutContext);
  const userDetails = JSON.parse(localStorage.getItem("user"));
  const [saving, setSaving] = useState(false);

  const formatDate = (d) => {
    const date = new Date(d);
    const formattedDate = date.toLocaleDateString('en-GB', {
      day: 'numeric', month: 'short'
    }).replace(/ /g, '-');
    return (formattedDate);
  }

  return (
    <>
      <List className="cartContent cartContent-root" sx={{ width: "100%" }}>
        {items.map((v, i) => {
          return (
            <>
              <ListItem
                alignItems="flex-start"
                style={{ backgroundColor: v.default && colors.secondary }}
              >
                <ListItemText
                  primary={
                    <></>
                    // <span
                    //   style={{ color: colors.primary }}
                    //   className="orders_content_title"
                    // >{`${v.status}`}</span>
                  }
                  secondary={
                    <>
                      <div>
                        <span style={{ color: colors.primary }} className="orders_content_title">{`#${v.orderId}`}</span>
                      </div>
                      <div>
                        <span className="orders_content_secondary">{`${formatDate(v.updated)}`}</span>
                      </div>
                      <div>
                        <span className="orders_content_secondary">{`Status: ${v.status}`}</span>
                      </div>
                      <div>
                        <span className="orders_content_secondary">{`Items:`}</span>
                      </div>
                      
                      {v.products.map((product, i) => (
                        <div style={{ marginLeft: 10 }}>
                          <span className="orders_content_secondary">{`${product.name} x ${product.quantity}`}</span>
                        </div>
                      ))}
                      {/* <div>
                        <span className="orders_content_secondary">{` Shipping: ₹${v.shipping} `}</span>
                        {v.box && (
                          <span className="orders_content_secondary">{`(Box Included)`}</span>
                        )}
                      </div> */}
                      <div style={{ height: 1, width: "50%", backgroundColor: "grey", marginTop: 5, marginBottom: 5, marginLeft: 10 }}>
                        {/* <span className="orders_content_secondary_total">{`Amount: ₹${parseInt(v.total) + parseInt(v.shipping)}`}</span> */}
                      </div>
                      <div style={{ marginLeft: 10 }}>
                        <span className="orders_content_secondary_total">{`Amount: ₹${parseFloat(v.total) + parseFloat(v.shipping)}`}</span>
                      </div>
                      <div>
                        <span style={{ color: v.paymentStatus === 'Failed' ? colors.warning : colors.primary }} className="orders_content_secondary">{`Payment Status - ${v.paymentStatus}`}</span>
                      </div>
                      <div style={{ marginTop: 10 }}>
                        {v.paymentStatus !== "Paid" && v.paymentStatus !== "Payment Completed" && (
                          <PayButton
                            action={async () => {
                              if (v.paymentStatus === "Processing Payment") {
                                localStorage.setItem("orderId", v.orderId);
                                window.location.href = v.paymentLink;
                              } else {
                                let localCartArray = v.products.map(vc => ({ id: Date.now() + Math.random().toFixed(), productId: vc.id, quantity: vc.quantity }));
                                console.log(localCartArray);
                                await addToCartAPI({
                                  recalled: false, setSaving: setSaving, cart: [], operator: "+", selectedProduct: products.filter(p => p.productId === localCartArray[0].productId)[0], setAddress: setAddress, setCart: setCart, setIsUserLoggedIn: () => { }, setOpenSnackbar: setOpenSnackbar, setOpenSnackbarContent: setOpenSnackbarContent, setOrders: setOrders, setSidePage: setSidePage, setSnackSeverity: setSnackSeverity, setSubPage: setSubPage, setUserDetails: () => { }, fromCart: false, isPlacedOrder: false, reload: false, payload: localCartArray, reOrder: true
                                })
                              }

                              // createOrder({
                              //   address: address,
                              //   selectedProduct: selectedProduct,
                              //   setAddress: setAddress,
                              //   setCart: setCart,
                              //   setOpenSnackbar: setOpenSnackbar,
                              //   setOpenSnackbarContent: setOpenSnackbarContent,
                              //   setOrderId: setOrderId,
                              //   setOrders: setOrders,
                              //   setSidePage: setSidePage,
                              //   setSnackSeverity: setSnackSeverity,
                              //   userDetails: userDetails,
                              //   setSubPage: setSubPage,
                              //   orders: orders,
                              //   products: products,
                              //   cart: cart
                              // });
                            }}
                            content={
                              v.paymentStatus === "Processing Payment" ?
                                <>
                                  <span>Retry Payment</span>
                                  <ReplayIcon style={{ width: 20, marginLeft: 6 }} />
                                </> : <>
                                  <span>Re-order Items</span>
                                  <ReplayIcon style={{ width: 20, marginLeft: 6 }} />
                                </>


                            }
                            mode={"active"}
                            style={{ color: "black" }}
                          />
                        )
                        }
                      </div>

                    </>
                  }
                />
              </ListItem>
              {/* <Divider variant="inset" component="li" /> */}
            </>
          );
        })}
        <>
          <ListItem>
            {width > 570 && (
              <div
                style={{
                  // backgroundColor: "red",
                  marginTop: 10,
                  // marginLeft: width < 570 && -20,
                  width: "100vw",
                  textAlign: "center",
                }}
              ></div>
            )}
          </ListItem>
        </>
      </List>
    </>
  );
}
