import {
  Box,
  Button,
  Divider,
  Modal,
  OutlinedInput,
  TextField,
} from "@mui/material";
import { useContext, useState } from "react";
import { LayoutContext } from "../ShopLayout";
import Fade from "@mui/material/Fade";
import bg from "../../../assets/background.webp";
import { ProductCartButtonSm } from "../../Shared/ProductCartButtonSm";
import makeApiRequest from "../../Utils/api";
import { colors } from "../../Shared/Constants";

export default function AddressModal({ open, handleClose, initialState, id }) {
  const {
    width,
    setSnackSeverity,
    setOpenSnackbar,
    setOpenSnackbarContent,
    address,
    setAddress,
  } = useContext(LayoutContext);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: width > 570 ? "40vw" : "90vw",
    boxShadow: 24,
    p: 4,
    height: "80vh",
  };

  const defaultFormState = initialState;
  const [formData, setFormData] = useState(defaultFormState);
  const [saving, setSaving] = useState(false);
  const userDetails = JSON.parse(localStorage.getItem("user"));
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const removeFormState = () => {
    setFormData({
      name: "",
      line1: "",
      line2: "",
      city: "",
      state: "",
      pin: "",
      phone: "",
      default: true,
    });
  };

  const handleSubmit = async (e) => {
    setSaving(true);
    e.preventDefault();
    const formDataJSON = {
      id: id === "1" ? Date.now() + Math.random().toFixed() : id,
      name: formData.name,
      line1: formData.line1,
      line2: formData.line2,
      city: formData.city,
      state: formData.state,
      pin: formData.pin,
      phone: formData.phone,
      default: true,
    };
    let addressList = address;
    let addedAddressIndex = addressList.findIndex((v) => v.id === id);
    addressList = addressList.map((v) => {
      return { ...v, default: false };
    });
    if (addedAddressIndex !== -1) {
      addressList[addedAddressIndex] = formDataJSON;
    } else {
      addressList = [formDataJSON, ...addressList];
    }
    let res = await makeApiRequest(
      "POST",
      { userId: userDetails?.email, address: addressList },
      "/address/add",
      setSnackSeverity,
      setOpenSnackbar,
      setOpenSnackbarContent
    );
    if (res?.status === 200) {
      setAddress(addressList);
      removeFormState();
      handleClose();
    }
    setSaving(false);
    // console.log('Form data as JSON:', formDataJSON);
  };

  const deleteProduct = async (e) => {
    setSaving(true);
    let isDefaultDeleting = false;
    let addressList = address;
    let addedAddressIndex = addressList.findIndex((v) => v.id === id);
    if (addedAddressIndex !== -1) {
      isDefaultDeleting = addressList[addedAddressIndex].default;
      addressList.splice(addedAddressIndex, 1)
    }
    if(isDefaultDeleting) {
      if(addressList.length > 0) {
        addressList[0].default = true;
      }
    }
    
    let res = await makeApiRequest(
      "POST",
      { userId: userDetails?.email, address: addressList },
      "/address/add",
      setSnackSeverity,
      setOpenSnackbar,
      setOpenSnackbarContent
    );
    if (res?.status === 200) {
      setAddress(addressList);
      removeFormState();
      handleClose();
    }
    setSaving(false);
    // console.log('Form data as JSON:', formDataJSON);
  };

  return (
    <Modal
      open={open}
      //   onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Fade in={open}>
        <Box sx={{ ...style, backgroundImage: `url(${bg})` }}>
          <Box
            className="address_modal_background"
            sx={{ ...style, overflow: "auto" }}
          >
            <form onSubmit={handleSubmit}>
              <div>
                <span className="new_address_title">Add New Address</span>
                <OutlinedInput
                  placeholder="Full Name"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  className="input_text"
                  fullWidth
                  autoComplete="name"
                  style={{ marginTop: 20 }}
                  required
                />
                <OutlinedInput
                  placeholder="Phone"
                  name="phone"
                  value={formData.phone}
                  onChange={handleInputChange}
                  className="input_text"
                  fullWidth
                  autoComplete="phone"
                  style={{ marginTop: 20 }}
                  required
                />
                <OutlinedInput
                  placeholder="Address Line 1"
                  name="line1"
                  value={formData.line1}
                  onChange={handleInputChange}
                  className="input_text"
                  fullWidth
                  autoComplete="address-line1"
                  required
                />
                <OutlinedInput
                  name="line2"
                  value={formData.line2}
                  onChange={handleInputChange}
                  placeholder="Address Line 2"
                  className="input_text"
                  fullWidth
                  autoComplete="address-line2"
                />
                <OutlinedInput
                  name="pin"
                  value={formData.pin}
                  onChange={handleInputChange}
                  placeholder="PIN Code"
                  className="input_text"
                  fullWidth
                  autoComplete="postal-code"
                  required
                  type="number"
                />
                <OutlinedInput
                  name="city"
                  value={formData.city}
                  onChange={handleInputChange}
                  placeholder="City"
                  className="input_text"
                  fullWidth
                  autoComplete="city"
                  required
                />
                <OutlinedInput
                  name="state"
                  value={formData.state}
                  onChange={handleInputChange}
                  placeholder="State"
                  className="input_text"
                  fullWidth
                  autoComplete="state"
                  required
                />

                <div>
                  <div style={{ textAlign: "center", marginTop: 30 }}>
                    <ProductCartButtonSm
                      action={handleClose}
                      content={"Back"}
                      mode={"inactive"}
                      style={{
                        marginRight: 10,
                      }}
                    />
                    <Button
                      type="submit"
                      variant="contained"
                      sx={{
                        backgroundColor: colors.primary,
                        color: "black",
                        height: 42,
                      }}
                    >
                      {saving ? (
                        <div class="button-loader">
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                      ) : (
                        <span>Save</span>
                      )}
                    </Button>
                    {id !== "1" && (
                      <div>
                        <div style={{ textAlign: "center", marginTop: 30 }}>
                          <Button
                            onClick={() => deleteProduct({id: id, ...initialState})}
                            variant="contained"
                            fullWidth
                            sx={{
                              backgroundColor: colors.danger,
                              color: "white",
                              height: 42,
                            }}
                          >
                            {saving ? (
                              <div class="button-loader">
                                <div></div>
                                <div></div>
                                <div></div>
                              </div>
                            ) : (
                              <span>Delete</span>
                            )}
                          </Button>
                          {/* <ProductCartButtonSm
                      action={() => {}}
                      content={"Add"}
                      mode={"active"}
                      style={{
                        marginLeft: 10,
                      }}
                    /> */}
                        </div>
                      </div>
                    )}
                    {/* <ProductCartButtonSm
                    action={() => {

                    }}
                    content={saving ? "saving..." : "Save"}
                    mode={"active"}
                    style={{
                      marginLeft: 10,
                    }}
                  /> */}
                  </div>
                </div>
              </div>
            </form>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
}
