import React, { useContext } from "react";
import "./Nav.css";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Offcanvas from "react-bootstrap/Offcanvas";
import Person2RoundedIcon from "@mui/icons-material/Person2Rounded";
import { AppContext } from "../../App";
import { auth, signInWithGooglePopup } from "../Utils/firebase.utils";
import { signOut } from "firebase/auth";

export default function NavbarComponent() {
  const expand = "sm";
  const { setPage } = useContext(AppContext);
  const { isUserLoggedIn, setIsUserLoggedIn, userDetails, setUserDetails } =
    useContext(AppContext);
    // const { setSidePage, selectedPage, setSelectedPage, setSubPage } =
    // useContext(LayoutContext);

    const handleLogout = () => {               
      signOut(auth).then(() => {
      // Sign-out successful.
          setIsUserLoggedIn(false);
          setUserDetails({});
          localStorage.removeItem('user');
      }).catch((error) => {
      console.error(error);
      });
  }

  const logGoogleUser = async () => {
    const response = await signInWithGooglePopup();
    let resUserDetails = {
      name: response.user.displayName,
      email: response.user.email,
      admin: response.user.email === "bkevin1999@gmail.com" || response.user.email === "ranjithkingjimson7788@gmail.com"? true : false
    };
    localStorage.setItem("user", JSON.stringify(resUserDetails));
    setUserDetails(resUserDetails);
    setIsUserLoggedIn(true);
    console.log(response);
  };

  return (
    <>
      <Container>
        <Navbar
          key={expand}
          expand={expand}
          bg="transparent"
          variant="dark"
          className=" mb-3"
        >
          <Container fluid>
            <Navbar.Brand
              style={{ cursor: "pointer" }}
              onClick={() => setPage("home")}
            >
              {" "}
              <span className="logo">Jim Aquarium</span>{" "}
            </Navbar.Brand>
            <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
            >
              <Offcanvas.Header closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                  Jim Aquarium
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-end flex-grow-1 pe-3">
                  <Nav.Link onClick={() => setPage("shopLayout")}>
                    <span className="nav-item hover-underline-animation">
                      SHOP
                    </span>{" "}
                  </Nav.Link>
                  <Nav.Link href="#action1">
                    <span className="nav-item hover-underline-animation">
                      ABOUT
                    </span>{" "}
                  </Nav.Link>
                  {
                    isUserLoggedIn && (
                      <NavDropdown
                        className="nav-item hover-underline-animation"
                        title={
                          <span>
                            {" "}
                            <Person2RoundedIcon /> {`Hi ${userDetails?.name} !`}
                          </span>
                        }
                        id="nav-dropdown"
                      >
                        <NavDropdown.Item onClick={handleLogout} eventKey="4.2">
                          Log Out
                        </NavDropdown.Item>
                      </NavDropdown>
                    )
                    // <Nav.Link onClick={() => {}}><span > <Person2RoundedIcon /> {`Hi ${userDetails?.name} !`}</span></Nav.Link>
                  }
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      </Container>
    </>
  );
}
