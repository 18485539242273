import { useEffect } from "react";

export default function RedirectPay() {
    useEffect(() => {
        const params = window.location.href;
        let itemArray = params.split('?').slice(1, params.length - 1);
        const obj = itemArray.reduce((acc, item) => {
            const [key, value] = item.split("=");
            acc[key] = value;
            return acc;
        }, {});
        console.log(window.location.origin);
        localStorage.setItem("payValues", JSON.stringify(obj));
        window.location.href = window.location.origin + "/processpayment";
    }, [])
    
    return(
        <>
        Processing please wait...
        </>
    )
}