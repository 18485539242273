// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header_ul {
  font-family: "Nunito", sans-serif;
  text-decoration: underline;
  color: #b5dc74;
}

.header_topic {
    font-family: 'M PLUS Rounded 1c', sans-serif;
    font-size: 50px;
    font-weight: 900;
    color: white;
}

.header_content {
    font-family: 'M PLUS Rounded 1c', sans-serif;
    font-size: 18px;
    font-weight: 300;
    color: white;
}`, "",{"version":3,"sources":["webpack://./src/components/Home/Home.css"],"names":[],"mappings":"AAAA;EACE,iCAAiC;EACjC,0BAA0B;EAC1B,cAAc;AAChB;;AAEA;IACI,4CAA4C;IAC5C,eAAe;IACf,gBAAgB;IAChB,YAAY;AAChB;;AAEA;IACI,4CAA4C;IAC5C,eAAe;IACf,gBAAgB;IAChB,YAAY;AAChB","sourcesContent":[".header_ul {\n  font-family: \"Nunito\", sans-serif;\n  text-decoration: underline;\n  color: #b5dc74;\n}\n\n.header_topic {\n    font-family: 'M PLUS Rounded 1c', sans-serif;\n    font-size: 50px;\n    font-weight: 900;\n    color: white;\n}\n\n.header_content {\n    font-family: 'M PLUS Rounded 1c', sans-serif;\n    font-size: 18px;\n    font-weight: 300;\n    color: white;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
