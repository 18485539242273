import React, { useContext, useEffect, useState } from "react";
import "./Nav.css";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Offcanvas from "react-bootstrap/Offcanvas";
import Person2RoundedIcon from "@mui/icons-material/Person2Rounded";
import { AppContext } from "../../App";
import { auth, signInWithGooglePopup } from "../Utils/firebase.utils";
import { LayoutContext } from "../Shop/ShopLayout";
import { signOut } from "firebase/auth";
import CloseIcon from '@mui/icons-material/Close';

export const logGoogleUser = async (
  setUserDetails,
  setIsUserLoggedIn,
  setOpenSnackbar,
  setOpenSnackbarContent,
  setSnackSeverity,
  fromNav = true
) => {
  try {
    const response = await signInWithGooglePopup();

    let resUserDetails = {
     name: response.user.displayName,
    email: response.user.email,
    };
    localStorage.setItem("user", JSON.stringify(resUserDetails));
     if(fromNav) {
       setUserDetails(resUserDetails);
       setIsUserLoggedIn(true);
     }
     return response;
    // localStorage.setItem("user", JSON.stringify({ name: "kevin", email: "bkevin199@gmail.com" }));
    // return "some"

  } catch {
    setSnackSeverity('warning');
    setOpenSnackbarContent('Please login again');
    setOpenSnackbar(true);
    return null;
  }

};

export function NavbarHomeComponent() {
  const expand = "sm";
  const { isUserLoggedIn, setIsUserLoggedIn, userDetails, setUserDetails, setPage } =
    useContext(AppContext);
  const [showNavBar, setShowNavBar] = useState(false);
  const [user, setUser] = useState({});
  const {
    setSidePage,
    selectedPage,
    setSelectedPage,
    setSubPage,
    setOpenSnackbar,
    setOpenSnackbarContent,
    setSnackSeverity, setCart
  } = useContext(LayoutContext);

  const navigateOrders = () => {
    setSidePage("Orders");
    setSubPage("orders");
    setShowNavBar(false);
  };

  const navigateAdmin = () => {
    setSidePage("Admin");
    setSubPage("admin");
    setShowNavBar(false);
  };

  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        setIsUserLoggedIn(false);
        setUserDetails({});
        localStorage.removeItem("user");
        setSidePage("Categories");
        setSubPage("allProducts");
        setCart([]);
        setPage('home')
      })
      .catch((error) => {
        setIsUserLoggedIn(false);
        setUserDetails({});
        localStorage.removeItem("user");
        setSidePage("Categories");
        setSubPage("allProducts");
        setCart([]);

        setPage('home')
        console.error(error);
      });
    setIsUserLoggedIn(false);
    setUserDetails({});
    localStorage.removeItem("user");
    setSidePage("Categories");
    setSubPage("allProducts");
    setCart([]);
    setPage('home')
  };
  let checkUser = null;
  useEffect(() => {
    let userLocalCache = user;
    if (!checkUser) {
      checkUser = setInterval(() => {
        if (!userLocalCache?.email) {
          if(localStorage.getItem('user')) {
            let userLocal = JSON.parse(localStorage.getItem('user'))
            if (userLocal?.email) {
              setUser(userLocal);
              userLocalCache = userLocal;
            }
          }
          
        }
      }, 1000);
    }



    // return clearInterval(checkUser);
  }, [])

  return (
    <>
      <Container>
        <Navbar
          key={expand}
          expand={expand}
          bg="transparent"
          variant="dark"
          className=" mb-3"
        >
          <Container fluid>
            <Navbar.Brand
              style={{ cursor: "pointer" }}
              onClick={() => { setPage("shopLayout"); setSidePage("Categories"); setSubPage("allProducts") }}
            >
              {" "}
              <span className="logo">Jim Aquarium</span>{" "}
            </Navbar.Brand>
            <Navbar.Toggle onClick={() => setShowNavBar(true)} aria-controls={`offcanvasNavbar-expand-${expand}`} />
            <Offcanvas
              show={showNavBar}
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
            >
              <Offcanvas.Header>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                  Jim Aquarium
                </Offcanvas.Title>
                <CloseIcon onClick={() => setShowNavBar(false)} style={{ position: "absolute", right: 20, top: 30, height: 30 }} />
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-end flex-grow-1 pe-3">
                  <Nav.Link onClick={() => { setPage("shopLayout"); setShowNavBar(false) }}>
                    <span className="nav-item hover-underline-animation">
                      SHOP
                    </span>{" "}
                  </Nav.Link>
                  <Nav.Link onClick={() => { setShowNavBar(false); }} >
                    <span className="nav-item hover-underline-animation">
                      ABOUT
                    </span>{" "}
                  </Nav.Link>
                  {
                    !user?.email ? (
                      <Nav.Link
                        onClick={() => {
                          logGoogleUser(
                            setUserDetails,
                            setIsUserLoggedIn,
                            setOpenSnackbar,
                            setOpenSnackbarContent,
                            setSnackSeverity
                          );
                          setShowNavBar(false)
                        }}
                      >
                        <span className="nav-item hover-underline-animation">
                          {" "}
                          <Person2RoundedIcon /> LOGIN/SIGNUP
                        </span>
                      </Nav.Link>
                    ) : (
                      <NavDropdown
                        className="nav-item hover-underline-animation"
                        title={
                          <span>
                            {" "}
                            <Person2RoundedIcon /> {`Hi ${user?.name} !`}
                          </span>
                        }
                        id="nav-dropdown"
                      >
                        <NavDropdown.Item
                          onClick={navigateOrders}
                          eventKey="4.1"
                        >
                          Orders
                        </NavDropdown.Item>

                        {(userDetails?.email === "bkevin1999@gmail.com" || userDetails?.email === "ranjithkingjimson7788@gmail.com") && (
                          <NavDropdown.Item
                            onClick={navigateAdmin}
                            eventKey="4.2"
                          >
                            Admin
                          </NavDropdown.Item>
                        )}
                        <NavDropdown.Item onClick={handleLogout} eventKey="4.2">
                          Log Out
                        </NavDropdown.Item>
                        {/* <NavDropdown.Item eventKey="4.3">
                          Something else here
                        </NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item eventKey="4.4">
                          Separated link
                        </NavDropdown.Item> */}
                      </NavDropdown>
                    )
                  }
                </Nav>
              </Offcanvas.Body>
            </Offcanvas>
          </Container>
        </Navbar>
      </Container>
    </>
  );
}
