// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cart {
    /* position: absolute;
      bottom: 0;
      right: 0; */
    position: fixed;
    bottom: 0;
    right: 0;
    text-align: center;
    margin-bottom: 20px;
    margin-right: 20px;
    box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.38);
    border-radius: 100px;
    z-index: 1;
  }

  .settingUpText{
    font-family: "M PLUS Rounded 1c", sans-serif;
    font-size: 20px;
    font-weight: 500;
  }

  .settingUpSecondaryText{
    font-family: "M PLUS Rounded 1c", sans-serif;
    font-size: 16px;
    font-weight: 300;
  }

  
  


  .color-flow {
    /* ... other styles... */
    background: linear-gradient(to right, #b5dc74 10%, transparent 35%, transparent 65%, #b5dc74 90%);
    background-size: 200% 100%; /* Reduced background-size */
    animation: flow 1s linear infinite; /* Removed 'alternate' */
   }
   @keyframes flow {
    0% { background-position: 100%; } /* Start off-screen */
    100% { background-position: -100%; }  /* End just off-screen */
   }

`, "",{"version":3,"sources":["webpack://./src/components/Shop/ShopLayout.css"],"names":[],"mappings":"AAAA;IACI;;iBAEa;IACb,eAAe;IACf,SAAS;IACT,QAAQ;IACR,kBAAkB;IAClB,mBAAmB;IACnB,kBAAkB;IAIlB,+CAA+C;IAC/C,oBAAoB;IACpB,UAAU;EACZ;;EAEA;IACE,4CAA4C;IAC5C,eAAe;IACf,gBAAgB;EAClB;;EAEA;IACE,4CAA4C;IAC5C,eAAe;IACf,gBAAgB;EAClB;;;;;;EAMA;IACE,wBAAwB;IACxB,iGAAiG;IACjG,0BAA0B,EAAE,4BAA4B;IACxD,kCAAkC,EAAE,wBAAwB;GAC7D;GACA;IACC,KAAK,yBAAyB,EAAE,EAAE,qBAAqB;IACvD,OAAO,0BAA0B,EAAE,GAAG,wBAAwB;GAC/D","sourcesContent":[".cart {\n    /* position: absolute;\n      bottom: 0;\n      right: 0; */\n    position: fixed;\n    bottom: 0;\n    right: 0;\n    text-align: center;\n    margin-bottom: 20px;\n    margin-right: 20px;\n  \n    -webkit-box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.38);\n    -moz-box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.38);\n    box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.38);\n    border-radius: 100px;\n    z-index: 1;\n  }\n\n  .settingUpText{\n    font-family: \"M PLUS Rounded 1c\", sans-serif;\n    font-size: 20px;\n    font-weight: 500;\n  }\n\n  .settingUpSecondaryText{\n    font-family: \"M PLUS Rounded 1c\", sans-serif;\n    font-size: 16px;\n    font-weight: 300;\n  }\n\n  \n  \n\n\n  .color-flow {\n    /* ... other styles... */\n    background: linear-gradient(to right, #b5dc74 10%, transparent 35%, transparent 65%, #b5dc74 90%);\n    background-size: 200% 100%; /* Reduced background-size */\n    animation: flow 1s linear infinite; /* Removed 'alternate' */\n   }\n   @keyframes flow {\n    0% { background-position: 100%; } /* Start off-screen */\n    100% { background-position: -100%; }  /* End just off-screen */\n   }\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
