import { useContext, useEffect, useState } from "react";
import { LayoutContext } from "../ShopLayout";
import { useSpring, animated } from "@react-spring/web";
import { dummyAddress, dummyCart, dummyOrders } from "../../Shared/Constants";
import "./AdminContent.css";
import { AddressCard } from "../../Shared/AddressCard";
import { ProductCartButtonSm } from "../../Shared/ProductCartButtonSm";
import { OrdersCardAdmin } from "../../Shared/OrdersCardAdmin";
import OrderUpdateModal from "./OrderUpdateModal";
import { Autocomplete, TextField } from "@mui/material";
export function AdminContent() {
  const { width, setSidePage, setSubPage, orders } = useContext(LayoutContext);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState("");

  const springs = useSpring({
    from: { x: -50 },
    to: { x: 0 },
  });

  // const [listProducts, setListProducts] = useState(dummyProducts.products);

  // useEffect(() => {
  //   console.log(dummyProducts.products.filter((v) => v.categories.includes(selectedCategory)));
  //   setListProducts(dummyProducts.products.filter((v) => v.categories.includes(selectedCategory)))

  // }, [selectedCategory])
  const [currentOrders, setCurrentOrders] = useState(orders);
  const orderStatuses = ["All", "Order Recieved", "Processing", "Packed", "Shipped", "Cancelled"];
  const [selectedStatus, setSelectedStatus] = useState("All");
  const [showPaid, setShowPaid] = useState(false);
  const [showPostPaid, setShowPostPaid] = useState(false);
  const defaultOrdertStatusOptions = orderStatuses.map((string) => ({
    label: string,
  }));
  const handleInputChangeAutoCompleteOrders = (e, value) => {
    setSelectedStatus(value?.label);
  };

  useEffect(() => {
    if (selectedStatus === "All" || !selectedStatus) {
      setCurrentOrders(orders);
      console.log(orders);
    } else {
      const filteredOrders = orders.filter((item) => item.status === selectedStatus);
      setCurrentOrders(filteredOrders);
    }

  }, [selectedStatus]);
  useEffect(() => {
    if (showPaid) {
      setCurrentOrders(currentOrders.filter((item => item.paymentStatus === "Paid")))
    } else {
      if (selectedStatus === "All" || !selectedStatus) {
        setCurrentOrders(orders);
        console.log(orders);
      } else {
        const filteredOrders = orders.filter((item) => item.status === selectedStatus);
        setCurrentOrders(filteredOrders);
      }
    }
  }, [showPaid])
  useEffect(() => {
    if (showPostPaid) {
      setCurrentOrders(currentOrders.filter((item => item.paymentStatus === "Payment Awaiting")))
    } else {
      if (selectedStatus === "All" || !selectedStatus) {
        setCurrentOrders(orders);
        console.log(orders);
      } else {
        const filteredOrders = orders.filter((item) => item.status === selectedStatus);
        setCurrentOrders(filteredOrders);
      }
    }
  }, [showPostPaid])

  return (
    <>
      <Autocomplete
        onChange={handleInputChangeAutoCompleteOrders}
        // disabled={saving}
        defaultValue={selectedStatus}
        // style={{width: "60vw", marginLeft: "20vw", marginTop: 40}}
        style={{ width: "60vw", margin: "auto", marginTop: 45 }}
        renderOption={(props, option) => {
          const { label } = option;
          return (
            <span
              {...props}
              style={{
                backgroundColor: "#120a28",
                color: "white",
              }}
            >
              {label}
            </span>
          );
        }}
        disablePortal
        id="combo-box-demo"
        options={defaultOrdertStatusOptions}
        sx={{ marginTop: 2 }}
        renderInput={(params) => (
          <TextField
            // disabled={saving}
            onChange={handleInputChangeAutoCompleteOrders}
            name="paymentStatus"
            {...params}
            label="Order Status"
          />
        )}
      />
      <div style={{  marginTop: 20, display:"flex", alignItems:"center",justifyContent:"center" }}>
        <input style={{ width: 20, height: 20 }} type="checkbox" checked={showPaid} onChange={(e) => { setShowPaid(e.target.checked); console.log(e.target.checked); }} />
        <span style={{ color: "white", fontSize: 16, marginLeft: 20, }}>Show only Paid Orders</span>
      </div>
      <div style={{  marginTop: 20, display:"flex", alignItems:"center",justifyContent:"center" }}>
        <input style={{ width: 20, height: 20 }} type="checkbox" checked={showPostPaid} onChange={(e) => { setShowPostPaid(e.target.checked); console.log(e.target.checked); }} />
        <span style={{ color: "white", fontSize: 16, marginLeft: 20, }}>Show only Post Paid Orders</span>
      </div>
      <animated.div
        className={"ordersContent-root"}
        style={{
          // backgroundColor: "red",
          width: width > 570 ? "60vw" : "90vw",
          display: "inline-flex",
          marginLeft: "5vw",
          marginTop: 30,
          height: width > 570 ? "70vh" : "50vh",
          position: width > 570 ? "fixed" : null,
          ...springs,
        }}
      >

        <OrdersCardAdmin
          items={currentOrders}
          isOpenModal={isOpenModal}
          setIsOpenModal={setIsOpenModal}
          setSelectedOrder={setSelectedOrder}
        />
      </animated.div>
      {selectedOrder !== "" && (
        <OrderUpdateModal
          open={true}
          handleClose={() => {
            // setSidePage("Admin");
            // setSubPage("allProducts")
            console.log("Closed");
            setSelectedOrder("")
          }}
          orderDetails={selectedOrder}
        />
      )}
      {width < 570 && (
        <div
          style={{
            // backgroundColor: "red",
            marginTop: 30,
            // marginLeft: width < 570 && -20,
            width: "100vw",
            textAlign: "center",
          }}
        ></div>
      )}
    </>
  );
}
