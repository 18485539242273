import {
  Autocomplete,
  Box,
  Button,
  Divider,
  Modal,
  OutlinedInput,
  TextField,
} from "@mui/material";
import { useContext, useState } from "react";
import { LayoutContext } from "../ShopLayout";
import Fade from "@mui/material/Fade";
import bg from "../../../assets/background.webp";
import { ProductCartButtonSm } from "../../Shared/ProductCartButtonSm";
import { colors } from "../../Shared/Constants";
import makeApiRequest from "../../Utils/api";
import "./AdminContent.css";

export default function ProductAddModal({
  open,
  handleClose,
  initialState = {
    name: "",
    price: "",
    desc: "",
    size: "",
    categories: "",
    stock: "",
    img: "",
    defaultWeight: "",
    bunch: "",
    box: "no",
  },
  id = Date.now() + Math.random().toFixed(),
}) {
  const {
    width,
    setOpenSnackbar,
    setOpenSnackbarContent,
    setSnackSeverity,
    setProducts,
    products,
    categories,
    setCategories,
  } = useContext(LayoutContext);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: width > 570 ? "40vw" : "90vw",
    boxShadow: 24,
    p: 4,
    height: "80vh",
  };
  const inputStyle = {
    // color: "red"
  };
  const defaultOptions = categories.map((string) => ({ label: string }));
  const [categoryOptions, setCategoryOptions] = useState(defaultOptions);
  const defaultFormState = initialState;
  const [saving, setSaving] = useState(false);

  const [formData, setFormData] = useState(defaultFormState);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleCheckChanege = (e) => {
    const { checked } = e.target;
    setFormData({ ...formData, box: checked ? "yes" : "no" });
  };
  const handleInputChangeAutoComplete = (e, value) => {
    setFormData({ ...formData, ["categories"]: value?.label });
  };
  const handleCustomCategory = (e) => {
    const { value } = e.target;
    if (value.length > 0) {
      setCategoryOptions([...defaultOptions, { label: value }]);
    } else {
      setCategoryOptions(defaultOptions);
    }
  };
  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        setFormData({ ...formData, img: event.target.result });
      };
      reader.readAsDataURL(file);
    }
  };
  const removeFormState = () => {
    setFormData({
      name: "",
      price: "",
      desc: "",
      size: "",
      categories: "",
      stock: "",
      img: "",
      defaultWeight: "",
      bunch: "",
      box: "no"
    });
  };
  const handleSubmit = async (e) => {
    setSaving(true);
    e.preventDefault();
    let theme = "dark";
    const formDataJSON = {
      productId: id === "1" ? Date.now() + Math.random().toFixed() : id,
      name: formData.name,
      price: formData.price,
      desc: formData.desc,
      size: formData.size,
      categories: formData.categories
        ? formData.categories
        : defaultOptions[0].label,
      stock: formData.stock,
      img: formData.img,
      theme: theme,
      defaultWeight: formData.defaultWeight,
      bunch: formData.bunch,
      box: formData.box
    };
    let res = await makeApiRequest(
      "POST",
      formDataJSON,
      "/products/add",
      setSnackSeverity,
      setOpenSnackbar,
      setOpenSnackbarContent
    );
    if (res?.status === 200) {
      const categoriesArray = [
        ...new Set([formData.categories, ...categories]),
      ];
      setCategories(categoriesArray);
      if (id === "1") {
        setProducts([formData, ...products]);
      } else {
        setProducts((prevProducts) =>
          prevProducts.map((item) => {
            if (item.productId === formDataJSON.productId) {
              return formDataJSON;
            } else {
              return item;
            }
          })
        );
      }

      removeFormState();
      handleClose();
    }
    setSaving(false);
    // console.log('Form data as JSON:', formDataJSON);
  };

  const deleteProduct = async (e) => {
    setSaving(true);
    let res = await makeApiRequest(
      "POST",
      { productId: id },
      "/products/delete",
      setSnackSeverity,
      setOpenSnackbar,
      setOpenSnackbarContent
    );
    if (res?.status === 200) {
      const deletedProductList = products.filter((v) => v.productId != id);

      setProducts(deletedProductList);
      removeFormState();
      handleClose();
    }
    setSaving(false);
  };

  return (
    <Modal
      open={open}
      //   onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Fade in={open}>
        <Box sx={{ ...style, backgroundImage: `url(${bg})` }}>
          <Box
            className="address_modal_background"
            sx={{ ...style, overflow: "auto" }}
          >
            <div>
              <span className="new_address_title">Add New Product</span>
              <form onSubmit={handleSubmit}>
                <OutlinedInput
                  required
                  placeholder="Name"
                  className="input_text"
                  fullWidth
                  style={{ marginTop: 20 }}
                  name="name"
                  onChange={handleInputChange}
                  value={formData.name}
                  disabled={saving}
                />
                <OutlinedInput
                  required
                  placeholder="Price"
                  className="input_text"
                  fullWidth
                  name="price"
                  onChange={handleInputChange}
                  value={formData.price}
                  disabled={saving}
                />
                <Autocomplete
                  onChange={handleInputChangeAutoComplete}
                  disabled={saving}
                  defaultValue={
                    formData.categories === ""
                      ? defaultOptions[0]
                      : formData.categories
                  }
                  renderOption={(props, option) => {
                    const { label } = option;
                    return (
                      <span
                        {...props}
                        style={{
                          backgroundColor: "#120a28",
                          color: "white",
                        }}
                      >
                        {label}
                      </span>
                    );
                  }}
                  disablePortal
                  id="combo-box-demo"
                  options={categoryOptions}
                  sx={{ marginTop: 2 }}
                  renderInput={(params) => (
                    <TextField
                      disabled={saving}
                      onChange={handleCustomCategory}
                      name="categories"
                      {...params}
                      label="Categories"
                    />
                  )}
                />
                <OutlinedInput
                  required
                  disabled={saving}
                  placeholder="Description"
                  className="input_text"
                  fullWidth
                  name="desc"
                  onChange={handleInputChange}
                  value={formData.desc}
                />
                <OutlinedInput
                  disabled={saving}
                  required
                  placeholder="Size"
                  className="input_text"
                  fullWidth
                  name="size"
                  onChange={handleInputChange}
                  value={formData.size}
                />
                <OutlinedInput
                  disabled={saving}
                  required
                  placeholder="Stock"
                  className="input_text"
                  fullWidth
                  type="number"
                  name="stock"
                  onChange={handleInputChange}
                  value={formData.stock}
                />
                <OutlinedInput
                  disabled={saving}
                  required
                  placeholder="Weight"
                  className="input_text"
                  fullWidth
                  type="number"
                  name="defaultWeight"
                  onChange={handleInputChange}
                  value={formData.defaultWeight}
                />
                <OutlinedInput
                  disabled={saving}
                  required
                  placeholder="Bunch"
                  className="input_text"
                  fullWidth
                  type="number"
                  name="bunch"
                  onChange={handleInputChange}
                  value={formData.bunch}
                />
                <div style={{marginTop: 10}}>
                  <input
                    type="checkbox"
                    onChange={handleCheckChanege}
                    checked={formData.box === "yes"}
                  />
                  <span style={
                    {color: "white", marginLeft: 10, fontSize: 16}
                  }>Promotion Display</span>
                </div>
                <Button
                  disabled={saving}
                  sx={{
                    backgroundColor: colors.primary,
                    color: "black",
                    marginTop: 2,
                  }}
                  fullWidth
                  variant="contained"
                  component="label"
                >
                  Upload File
                  <input
                    type="file"
                    accept="image/*"
                    hidden
                    onChange={handleImageUpload}
                    // value={formData.img}
                  />
                </Button>
                <TextField
                  required
                  sx={{ marginTop: 2 }}
                  fullWidth
                  placeholder="Select Image"
                  value={formData.img}
                />

                <div>
                  <div style={{ textAlign: "center", marginTop: 30 }}>
                    <ProductCartButtonSm
                      action={() => {
                        if (!saving) {
                          removeFormState();
                          handleClose();
                        }
                      }}
                      content={"Back"}
                      mode={"inactive"}
                      style={{
                        marginRight: 10,
                      }}
                    />
                    <Button
                      type="submit"
                      variant="contained"
                      sx={{
                        backgroundColor: colors.primary,
                        color: "black",
                        height: 42,
                      }}
                    >
                      {saving ? (
                        <div class="button-loader">
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                      ) : (
                        <span>Add</span>
                      )}
                    </Button>
                    {/* <ProductCartButtonSm
                      action={() => {}}
                      content={"Add"}
                      mode={"active"}
                      style={{
                        marginLeft: 10,
                      }}
                    /> */}
                  </div>
                </div>
                {id !== "1" && (
                  <div>
                    <div style={{ textAlign: "center", marginTop: 30 }}>
                      <Button
                        onClick={deleteProduct}
                        variant="contained"
                        fullWidth
                        sx={{
                          backgroundColor: colors.danger,
                          color: "white",
                          height: 42,
                        }}
                      >
                        {saving ? (
                          <div class="button-loader">
                            <div></div>
                            <div></div>
                            <div></div>
                          </div>
                        ) : (
                          <span>Delete</span>
                        )}
                      </Button>
                      {/* <ProductCartButtonSm
                      action={() => {}}
                      content={"Add"}
                      mode={"active"}
                      style={{
                        marginLeft: 10,
                      }}
                    /> */}
                    </div>
                  </div>
                )}
              </form>
            </div>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
}
