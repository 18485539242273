import React, { useContext, useState } from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { colors } from "../../Shared/Constants";
import "./PaymentContent.css";
import { LayoutContext } from "../ShopLayout";

const PaymentMethod = () => {
  const { upiMethod, setUpiMethod } = useContext(LayoutContext);

  const handleChange = (event) => {
    setUpiMethod(event.target.value);
  };

  return (
    <FormControl
      style={{
        display: "flex",
        alignItems: "center",
        marginTop: "4vh",
        marginLeft: 20,
      }}
    >
      {/* <InputLabel
        id="payment-method-label"
        style={{ lineHeight: "70px" }} // Adjust lineHeight as needed
      >
        <span
          className="payment_content_primary"
          style={{ color: colors.primary }}
        >
          Payment Method:
        </span>
      </InputLabel> */}
      {/* <Select
        labelId="payment-method-label"
        id="payment-method"
        value={upiMethod}
        onChange={handleChange}
        style={{
          fontSize: "16px",
          height: "32px",
          marginLeft: 120,
        }}
        variant="outlined"
      >
        <MenuItem value="Google Pay">Google Pay</MenuItem>
        <MenuItem value="PayTM">PayTM</MenuItem>
        <MenuItem value="PhonePe">PhonePe</MenuItem>
        <MenuItem value="Other UPI">Other UPI</MenuItem>
      </Select> */}
    </FormControl>
  );
};

export default PaymentMethod;
