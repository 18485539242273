import { colors } from "./Constants";
import { useSpring, animated } from "@react-spring/web";
import './Shared.css';

export function CartButton({mode, content, icon=null, style = {}, action}) {
    const borderColor = mode === "active" ? colors.primary : 'white';
    const backgroundColor =  mode === "active" ? colors.primary : '';
    const textColor =  mode === "active" ? '' : 'white';
    const springs = useSpring({
        from: { x: -50 },
        to: { x: 0 },
      });
    return(
        <>
            <animated.div onClick={action} style={{
                ...style,
                backgroundColor: backgroundColor,
                padding: content === "" ? 20 : 15,
                border: `solid ${borderColor} 1px`,
                display: "inline-block",
                cursor: "pointer",
                textAlign: "center",
                borderRadius: 50,
                ...springs

                // borderRadius: 5
            }}>
                <span className="cart_content" style={{color: textColor}}>{content} {icon ? icon : ''}</span>
            </animated.div>
        </>
    )
}

