import { useContext, useState } from "react";
import { Grid } from "@mui/material";
import { LayoutContext } from "../ShopLayout";
import { useSpring, animated } from "@react-spring/web";
import { CategoryButtonMd } from "../../Shared/CategoryButtonMd";
import { CategoryButtonSm } from "../../Shared/CategoryButtonSm";

export function AdminNav() {
  const { width, setSidePage, setSubPage } =
    useContext(LayoutContext);
  const [currentTab, setCurrentTab] = useState("Orders");
  const springs = useSpring({
    from: { x: -50 },
    to: { x: 0 },
  });
  const sideItems = [
    {
      name: "Back",
      action: () => {
        setSidePage("Categories");
        setSubPage("allProducts");
      },
    },
    {
      name: "Orders",
      action: () => {
        setCurrentTab("Orders");
        setSidePage("Admin");
        setSubPage("admin");
      },
    },
    // {
    //   name: "Gateway",
    //   action: () => {
    //     setCurrentTab("Gateway")
    //     setSidePage("Admin");
    //     setSubPage("gateway");
    //   },
    // },
   
  ];

  // useEffect(() => {
  //   console.log(selectedCategory);
  // }, [selectedCategory])

  //   useEffect(() => {
  //     console.log(width);
  //   }, [width]);

  return (
    <>
      {width > 575 ? (
        <animated.div
          style={{
            display: "inline-flex",
            width: "20vw",
            marginLeft: 30,
            marginTop: 60,
            ...springs,
          }}
        >
          <div>
            {sideItems.map((v, i) => {
              return (
                <>
                  <CategoryButtonMd
                    action={v.action}
                    content={v.name}
                    mode={v.name === currentTab ? "active" : "inactive"}
                  />
                  <div style={{ height: 10 }}></div>
                </>
              );
            })}
          </div>
        </animated.div>
      ) : (
        <>
          <animated.div
            style={{
              marginLeft: 30,
              marginTop: 60,
              ...springs,
            }}
          >
            <Grid container rowSpacing={3} columnSpacing={2}>
              {sideItems.map((v, i) => {
                return (
                  <>
                    <Grid item>
                      <CategoryButtonSm
                        action={v.action}
                        content={v.name}
                        mode={v.name === currentTab ? "active" : "inactive"}
                      />
                    </Grid>
                  </>
                );
              })}
            </Grid>
          </animated.div>
        </>
      )}
    </>
  );
}
