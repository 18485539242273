// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.root {
  background-repeat: no-repeat;
  background-size: cover;
  /* height: 100%; */
  min-height: 100vh;
  max-height: 100vh;
} 

`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,4BAA4B;EAC5B,sBAAsB;EACtB,kBAAkB;EAClB,iBAAiB;EACjB,iBAAiB;AACnB","sourcesContent":[".root {\n  background-repeat: no-repeat;\n  background-size: cover;\n  /* height: 100%; */\n  min-height: 100vh;\n  max-height: 100vh;\n} \n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
