// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.productContent {
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
  overflow: auto;
}

.productContent::-webkit-scrollbar { 
  display: none;  /* Safari and Chrome */
}`, "",{"version":3,"sources":["webpack://./src/components/Shop/Content/AllProducts.css"],"names":[],"mappings":";;AAEA;EACE,wBAAwB,GAAG,0BAA0B;IACnD,qBAAqB,GAAG,YAAY;EACtC,cAAc;AAChB;;AAEA;EACE,aAAa,GAAG,sBAAsB;AACxC","sourcesContent":["\n\n.productContent {\n  -ms-overflow-style: none;  /* Internet Explorer 10+ */\n    scrollbar-width: none;  /* Firefox */\n  overflow: auto;\n}\n\n.productContent::-webkit-scrollbar { \n  display: none;  /* Safari and Chrome */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
