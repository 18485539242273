import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { LayoutContext } from "../Shop/ShopLayout";
import { useContext, useState } from "react";
import {
  Avatar,
  Box,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import { colors } from "./Constants";
import { CategoryButtonSm } from "./CategoryButtonSm";
import { ProductCartButtonSm } from "./ProductCartButtonSm";
import EditIcon from "@mui/icons-material/Edit";
import { getProductsAndTotal } from "../Utils/appUtils";
export function AddressCard({
  items,
  setModalOpen,
  setSelectedAddress,
  setAddressId,
  setDefault,
  setShipping
}) {
  const { width, cart, products } = useContext(LayoutContext);

  return (
    <>
      <List className="cartContent cartContent-root" sx={{ width: "100%" }}>
        {items.map((v, i) => {
          const productTotal = getProductsAndTotal(cart, products, v.pin);
          if (v.default) {
            setShipping(productTotal.shipping);
          }
          return (
            <>
              <ListItem
                alignItems="flex-start"
                style={{ backgroundColor: v.default && colors.secondary }}
                onClick={() => setDefault(v)}
              >
                <ListItemText
                  primary={
                    <span className="address_content_title">
                      {`${v.name}`}
                      <EditIcon
                        onClick={() => {
                          setModalOpen(true);
                          setSelectedAddress(v);
                          setAddressId(v.id);
                        }}
                        sx={{ marginLeft: 2, width: 16, cursor: "pointer" }}
                      />{" "}
                    </span>
                  }
                  secondary={
                    <>
                      <div>
                        <span className="address_content_secondary">{`${v.phone}`}</span>
                      </div>
                      <div>
                        <span className="address_content_secondary">{`${v.line1}`}</span>
                      </div>
                      <div>
                        <span className="address_content_secondary">{`${v.line2}`}</span>
                      </div>
                      <div>
                        <span className="address_content_secondary">{`${v.city} - ${v.pin}`}</span>
                      </div>
                      <div>
                        <span className="address_content_secondary">{`${v.state}`}</span>
                      </div>
                      {
                        cart.length > 0 && <>
                          <div style={{ marginTop: 10 }}>
                            <span className="address_content_secondary" style={{ color: colors.primary }}>
                              {`Shipping - ₹${productTotal.shipping}`}{" "}

                            </span>
                          </div>
                          <div>
                            <span style={{ color: colors.primary }} className="address_content_ternary">{`(Delivered in 2 to 4 days)`}</span>
                          </div></>
                      }

                    </>
                  }
                />
              </ListItem>
              {/* <Divider variant="inset" component="li" /> */}
            </>
          );
        })}
        <>
          <ListItem>
            {/* {width > 570 && ( */}
              <div
                style={{
                  // backgroundColor: "red",
                  marginTop: 10,
                  // marginLeft: width < 570 && -20,
                  width: "100vw",
                  textAlign: "center",
                }}
              >
                <ProductCartButtonSm
                  action={() => {setAddressId("1")
                  setModalOpen(true); }}
                  content={"Add New Address"}
                  mode={"active"}
                  style={
                    {
                      // borderRadius: 100,
                    }
                  }
                />
              </div>
            {/* )} */}
          </ListItem>
        </>
      </List>
    </>
  );
}
