// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.css-6hp17o-MuiList-root-MuiMenu-list {
    background-color: #140820;
    color: #bddb81;
}

.payment_content_primary{
    font-family: "M PLUS Rounded 1c", sans-serif;
    font-size: 28px;
    font-weight: 500;
  }
`, "",{"version":3,"sources":["webpack://./src/components/Shop/Payment/PaymentContent.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,cAAc;AAClB;;AAEA;IACI,4CAA4C;IAC5C,eAAe;IACf,gBAAgB;EAClB","sourcesContent":[".css-6hp17o-MuiList-root-MuiMenu-list {\n    background-color: #140820;\n    color: #bddb81;\n}\n\n.payment_content_primary{\n    font-family: \"M PLUS Rounded 1c\", sans-serif;\n    font-size: 28px;\n    font-weight: 500;\n  }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
