import bg from '../../assets/background.webp'

export default function QuickRedirect  () {
    window.close();
    return(
        <>
        <div style={{padding: 20, backgroundImage: `url(${bg})`, color: "white", height: "100vh"}}>
            Payment Done. You can close this window
        </div>
        </>
    )
}