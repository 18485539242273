import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import { LayoutContext } from "../Shop/ShopLayout";
import { useContext, useState } from "react";
import { colors, dummyProducts } from "./Constants";
export function ProductCard({
  name,
  size,
  selectedCategory,
  productCategory,
  price,
  img,
  theme,
  defaultWeight,
  bunch,
  stock,
}) {
  const { width } = useContext(LayoutContext);
  // const [isDark, setIsDark] = useState(true);
  // Utils.isImageDark(img)
  //   .then((v) => {
  //     console.log(v);
  //     setIsDark(v);
  //   })
  //   .catch((e) => console.log(e.message));

  if (selectedCategory === productCategory || selectedCategory === "All")
    return (
      <>
        <Card
          style={{
            margin: width > 570 ? 10 : 10,
            cursor: "pointer",
            position: "relative",
            display: "inline-block"
          }}
          sx={{ width: width > 570 ? 200 : 175 }}
        >
          <CardMedia
          // sx={{ height: width > 570 ? 195 : 160 }}
          // style={{ backgroundColor: "red" }}
          >
            <img
              // src={`https://iili.io/JGGyASR.md.jpg`}
              src={`${img}`}
              alt={name}
              style={{
                height: width > 570 ? 150 : 160,
                width: width > 570 ? 200 : 175,
                objectFit: "cover",
              }}
            />
            {parseInt(stock) <= 10 && (
              <div
                style={{
                  backgroundColor:
                    parseInt(stock) === 0 ? colors.danger : colors.warning,
                  width: 10,
                  height: 10,
                  borderRadius: 100,
                  position: "absolute",
                  float: "right",
                  right: 7,
                  top: 7,
                  zIndex: 1000,
                }}
              ></div>
            )}

            <div
              className="productDescCard"
              style={{
                position: "relative",
                // backgroundImage: `url(${dummyProducts.products[0].img})`,
                backgroundColor: "#696865",
                padding: 2,
                paddingRight: 15,
                paddingLeft: 15,
                color: theme === "dark" && "white",
              }}
            >
              <div style={{ float: "right" }}>
                <span>&#x20B9;{`${price}`}</span>
              </div>
              <div
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                <span>{`${name}`}</span>
              </div>
            </div>
          </CardMedia>

          {/* <div
            style={{
              backgroundColor: colors.primary,
              textAlign: "center",
              padding: 5,
            }}
          >
            <span className="product_button_cart">ADD TO CART</span>
          </div> */}
        </Card>
      </>
    );
}
