import React, { useState, useEffect, useContext } from "react";
import QRCode from "qrcode.react"; // You'll need a QR code library
import makeApiRequest from "../../Utils/api";
import { LayoutContext, ordersAPI } from "../ShopLayout";
import { colors } from "../../Shared/Constants";
import "./OrdersContent.css";
import { ProductCartButtonSm } from "../../Shared/ProductCartButtonSm";

function OrdersLoadingUPI({ orderId, links }) {
  // const parsedLinks = JSON.parse(links);
  const selectedLink = localStorage.getItem("link")
  const mode = localStorage.getItem("mode");
  const [amount, setAmount] = useState(null);
  // const [selectedLink, setSelectedLink] = useState(parsedLinks?.upiLink);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const {
    width,
    setSnackSeverity,
    setOpenSnackbar,
    setOpenSnackbarContent,
    setSubPage,
    setSidePage,
    setOrders,
  } = useContext(LayoutContext);
  const userDetails = JSON.parse(localStorage.getItem("user"));

  const [remainingTime, setRemainingTime] = useState(0); // 120 seconds
  const [paymentStatus, setPaymentStatus] = useState("Pending");
  const uniqueness = localStorage.getItem("amount")
    ? localStorage.getItem("amount")
    : "0";

  useEffect(() => {
    const storedStartTime = localStorage.getItem("startTime");
    if (storedStartTime) {
      const now = new Date().getTime();
      const timeElapsed = now - parseInt(storedStartTime, 10);
      const remainingTimeLocal = Math.max(0, 600000 - timeElapsed); // 10 minutes in milliseconds
      // Check if 10 minutes have passed
      if (remainingTimeLocal === 0) {
        localStorage.removeItem("startTime");
      } else {
        console.log("Update Timer Ran", remainingTimeLocal);
        setRemainingTime(remainingTimeLocal);
        updateTimer(remainingTimeLocal); // Start the timer
      }
    }
  }, []); // Run the useEffect only on initial render
  // Function to update the displayed timer
  const updateTimer = (remainingTimeLocal) => {
    console.log("Update Timer Ran");
    setTimeout(() => {
      if (remainingTimeLocal > 0) {
        console.log("setting time");
        setRemainingTime(remainingTimeLocal - 1000); // Decrease time by 1 second
        updateTimer(remainingTimeLocal - 1000); // Schedule the next update
      } else {
        localStorage.removeItem("startTime");
        checkPayment();
      }
    }, 1000); // Timeout for 1 second
  };
  // Helper function to format the time
  const formatTime = (timeInMilliseconds) => {
    const minutes = Math.floor(timeInMilliseconds / 60000);
    const seconds = ((timeInMilliseconds % 60000) / 1000).toFixed(0);
    return `${minutes}:${seconds.padStart(2, "0")}`;
  };

  const fetchAmount = async () => {
    setIsLoading(true);
    try {
      const response = await makeApiRequest(
        "POST",
        { userId: userDetails?.email },
        "/orders/get",
        setSnackSeverity,
        setOpenSnackbar,
        setOpenSnackbarContent,
        false
      );
      if (!response.orders) {
        throw new Error("Failed to fetch amount");
      }
      setAmount(
        parseFloat(response.orders[0].total) +
          parseFloat(response.orders[0].shipping)
      );
      console.log(
        "Setting amount as ",
        parseFloat(response.orders[0].total) +
          parseFloat(response.orders[0].shipping)
      );
    } catch (error) {
      console.log(error);
      setError(error);
      setSnackSeverity("danger");
      setOpenSnackbarContent("Please try again later");
      setOpenSnackbar(true);
      localStorage.removeItem("remainingTime");
      localStorage.removeItem("orderId");
      localStorage.removeItem("link");
      localStorage.removeItem("mode");
      setSidePage("Orders");
      setSubPage("orders");
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    // switch (mode) {
    //   case "Google Pay":
    //     setSelectedLink(parsedLinks.gPayLink);
    //     break;
    //   case "PhonePe":
    //     setSelectedLink(parsedLinks.phonepeLink);
    //     break;
    //   case "PayTM":
    //     setSelectedLink(parsedLinks.paymentLink);
    //     break;
    //   default:
    //     setSelectedLink(parsedLinks.upiLink);
    // }
    fetchAmount();
  }, [orderId]);
  const setPaymentFailed = async () => {
    try {
      let res = await makeApiRequest(
        "POST",
        { orderId: orderId, paymentStatus: "Failed" },
        "/orders/update",
        setSnackSeverity,
        setOpenSnackbar,
        setOpenSnackbarContent,
        false
      );

      if (res?.status === 200) {
        await ordersAPI(
          setSnackSeverity,
          setOpenSnackbar,
          setOpenSnackbarContent,
          setOrders
        );

        localStorage.removeItem("orderId");
        localStorage.removeItem("link");
        localStorage.removeItem("mode");
        localStorage.removeItem("amount");
        localStorage.removeItem("startTime");
        setSidePage("Orders");
        setSubPage("orders");
      }
    } catch (e) {
      console.log(e);
    }
  };

  let runningInterval = false;
  const checkPayment = async () => {
    const isActive = localStorage.getItem("startTime");
    const orderIdCurrent = localStorage.getItem("orderId");
    try {
      if (isActive) {
        const response = await makeApiRequest(
          "POST",
          { order_id: orderIdCurrent },
          "/orders/status",
          setSnackSeverity,
          setOpenSnackbar,
          setOpenSnackbarContent,
          false
        );
        let paymentStatus = response?.data?.link_status;
        if (paymentStatus === "PAID") {
          // await removeData({ key: "paymentLink" });
          // await removeData({ key: "orderId" });
          // await removeData({ key: "amount" });
          // await removeData({ key: "startTime" });
          localStorage.removeItem("remainingTime");
          localStorage.removeItem("orderId");
          localStorage.removeItem("link");
          localStorage.removeItem("mode");
          localStorage.removeItem("amount");
          localStorage.removeItem("startTime");
          let resUpdate = await makeApiRequest(
            "POST",
            { orderId: orderIdCurrent, paymentStatus: "Paid" },
            "/orders/update",
            setSnackSeverity,
            setOpenSnackbar,
            setOpenSnackbarContent,
            false
          );
          if (resUpdate?.status == 200) {
            await ordersAPI(
              setSnackSeverity,
              setOpenSnackbar,
              setOpenSnackbarContent,
              setOrders
            );
            setSidePage("Orders");
            setSubPage("orders");
            // setAndRedirect({
            //   order: orders.filter(
            //     (o) => o.orderId === orderIdCurrent.orderId
            //   )[0],
            // });
          } else {
            await setPaymentFailed();
            // setTimeout(checkPayment, 10000);
          }
        } else if (
          paymentStatus === "EXPIRED" ||
          paymentStatus === "TERMINATED" ||
          paymentStatus === "TERMINATION_REQUESTED"
        ) {
          await setPaymentFailed();
          setTimeout(checkPayment, 10000);
        } else {
          console.log(paymentStatus);
          setTimeout(checkPayment, 10000);
        }
      } else {
        if (paymentStatus === "Pending") {
          console.log("Clearning Payment", paymentStatus);
          await setPaymentFailed();
        }
      }
    } catch (e) {
      setSnackSeverity("danger");
      setOpenSnackbarContent("Please try again later");
      setOpenSnackbar(true);
      localStorage.removeItem("remainingTime");
      localStorage.removeItem("orderId");
      localStorage.removeItem("link");
      localStorage.removeItem("mode");
      localStorage.removeItem("amount");
      localStorage.removeItem("startTime");
      setSidePage("Orders");
      setSubPage("orders");
      return;
    }
  };
  useEffect(() => {
    if (!runningInterval) {
      if (amount !== null) {
        setTimeout(checkPayment, 10000);
      }
    }
  }, [amount]);

  // const handleLinkChange = (event) => {
  //   setSelectedLink(event.target.value);
  // };

  return (
    <div>
      {isLoading && <p>Loading amount...</p>}
      {error && <p>Error: {error.message}</p>}

      {amount && (
        <div style={{ textAlign: "center" }}>
          <p
            className="order_content_primary"
            style={{ color: colors.primary }}
          >
            {` Amount to pay: ${amount.toString()}.${uniqueness}₹`}
          </p>
          {/* <div>
            <select
              onChange={handleLinkChange}
              style={{
                backgroundColor: colors.primary,
                height: 45,
                padding: 10,
              }}
            >
              <option
                value={parsedLinks.gPayLink}
                selected={mode === "Google Pay"}
              >
                Google Pay
              </option>
              <option
                value={parsedLinks.phonepeLink}
                selected={mode === "PhonePe"}
              >
                PhonePe
              </option>
              <option value={parsedLinks.paytmLink} selected={mode === "PayTM"}>
                PayTM
              </option>
              <option
                value={parsedLinks.upiLink}
                selected={mode === "Other UPI"}
              >
                UPI
              </option>
            </select>
          </div> */}

          {true && (
            <>
             
              <div style={{ marginTop: 30 }}>
                {/* <button onClick={() => window.open(selectedLink, '_blank')}>
                Pay Now
              </button> */}
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <p
                    className="order_content_secondary"
                    style={{ marginRight: "10px", color: colors.primary }}
                  >
                    Please wait while we fetch your payment
                  </p>
                  <div className="spinner">
                    {/* Your loading spinner component here */}
                  </div>
                </div>
                <div>
                  <p
                    className="order_content_secondary"
                    style={{ color: colors.primary }}
                  >
                    You will be redirected in: {formatTime(remainingTime)}
                  </p>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <p
                    className="order_content_ternary"
                    style={{ marginRight: "10px", color: colors.primary }}
                  >
                    Click below to retry the payment.
                  </p>
                </div>
                <ProductCartButtonSm
                  action={() => {
                    window.open(selectedLink, "_blank");
                  }}
                  content={"Pay Now"}
                  mode={"active"}
                  style={
                    {
                      // borderRadius: 100,
                    }
                  }
                />
                <div style={{ marginTop: 20, color: colors.primary }}>
                  <span
                    onClick={() => {
                      setPaymentFailed();
                    }}
                    className="orders_redirect"
                  >
                    Cancel and go back
                  </span>
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
}

export default OrdersLoadingUPI;
