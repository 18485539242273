import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { LayoutContext } from "../Shop/ShopLayout";
import { useContext, useState } from "react";
import {
  Avatar,
  Box,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { colors, dummyProducts } from "./Constants";
import { CategoryButtonSm } from "./CategoryButtonSm";
import { addToCartAPI } from "../Shop/ProductDetails/ProductContent";
import { AppContext } from "../../App";
export function CartCard({ items }) {
  const {
    width,
    setSubPage,
    setSidePage,
    selectedProduct,
    cart,
    setCart,
    setSnackSeverity,
    setOpenSnackbar,
    setOpenSnackbarContent,
    products,
    setAddress,
    setOrders
  } = useContext(LayoutContext);
  const { isUserLoggedIn, setIsUserLoggedIn, setUserDetails } =
    useContext(AppContext);
  const [saving, setSaving] = useState(false);

  const updateCart = (operator, v) => {
    addToCartAPI({
      recalled: false,
      setSaving: setSaving,
      setCart: setCart,
      setSidePage: setSidePage,
      setSnackSeverity: setSnackSeverity,
      setOpenSnackbar: setOpenSnackbar,
      cart: cart,
      operator: operator,
      selectedProduct: v,
      setIsUserLoggedIn: setIsUserLoggedIn,
      setOpenSnackbarContent: setOpenSnackbarContent,
      setSubPage: setSubPage,
      setUserDetails: setUserDetails,
      fromCart: true,
      setAddress: setAddress,
      setOrders: setOrders,
      isPlacedOrder: false,
      reload: false
    });
  };

  return (
    <>
      <List className="cartContent cartContent-root" sx={{ width: "100%" }}>
        {items.map((v, i) => {
          let currentProduct = products.filter(
            (product) => product.productId === v.productId
          )[0];
          if(currentProduct) {
            return (
              <>
                <ListItem alignItems="flex-start">
                  <ListItemAvatar>
                    {/* <Avatar  alt="Remy Sharp"  /> */}
                    <div
                      style={{
                        // backgroundColor: "red",
                        width: width > 570 ? 100 : 50,
                        height: width > 570 ? 100 : 50,
                      }}
                    >
                      <img
                        // src={`https://iili.io/JGGyASR.md.jpg`}
                        src={`${currentProduct?.img}`}
                        alt={currentProduct?.name}
                        style={{
                          height: width > 570 ? 100 : 50,
                          width: width > 570 ? 100 : 50,
                          objectFit: "cover",
                        }}
                      />
                    </div>
                  </ListItemAvatar>
                  <ListItemText
                    style={{ marginLeft: 20 }}
                    primary={
                      <>
                        <span className="cart_content_title">
                          {`${currentProduct.name} ${currentProduct.size}`}
                        </span>
                        <div
                          style={{
                            display: "inline-block",
                            // backgroundColor: "blue",
                            float: "right",
                            marginTop: 10,
                            marginRight: 10,
                          }}
                        >
                          <div
                            style={{
                              display: "inline-block",
                              padding: 2,
                              backgroundColor: "grey",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              if (!saving) {
                                updateCart("-", v);
                              }
  
                            }}
                          >
                            {saving ? <div class="loader"></div> : <RemoveIcon style={{ width: 20 }} />}
  
                          </div>
                          <div
                            style={{
                              display: "inline-block",
                              padding: 2,
                              paddingLeft: 7,
                              paddingRight: 7,
                            }}
                          >
                            <span className="cart_content_quantity">
                              {v.quantity}
                            </span>
                          </div>
                          <div
                            style={{
                              display: "inline-block",
                              padding: 2,
                              backgroundColor: "grey",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              if (!saving) {
                                updateCart("+", v);
                              }
  
                            }}
                          >
                            {saving ?
  
                              <div class="loader"></div> : <AddIcon style={{ width: 20 }} />}
  
                          </div>
                        </div>
                      </>
                    }
                    secondary={
                      <>
                        <span className="cart_content_secondary">
                          &#x20B9;{`${currentProduct.price}`}
                        </span>
                      </>
                    }
                  />
                </ListItem>
                {/* <Divider variant="inset" component="li" /> */}
              </>
            );
          } else {
            return(
              <></>
            )
          }
         
        })}
      </List>
    </>
  );
}
