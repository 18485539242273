import { useContext, useEffect, useState } from "react";
import { CategoryButtonMd } from "../../Shared/CategoryButtonMd";
import { CategoryButtonSm } from "../../Shared/CategoryButtonSm";
import "./SideNav.css";
import { Grid } from "@mui/material";
import { LayoutContext } from "../ShopLayout";
import { useSpring, animated } from '@react-spring/web';

export function SideNav() {
  const { selectedCategory, setSelectedCategory, width, categories, loadingCache } = useContext(LayoutContext);
  const springs = useSpring({
    from: { x: -50 },
    to: { x: 0 },
  });



  return (
    <>
        <>
        {!loadingCache && <animated.div
            style={{
              marginLeft: 30,
              marginTop: 60,
              
              ...springs
            }}
          >
            
            <Grid container rowSpacing={3} columnSpacing={2}>
              <Grid item>
                <CategoryButtonSm
                  action={() => {
                    setSelectedCategory("All");
                  }}
                  content={"All"}
                  mode={selectedCategory === "All" ? "active" : "inactive"}
                />
              </Grid>

              {categories.map((v, i) => (
                <Grid item>
                  <CategoryButtonSm
                    action={() => {
                      setSelectedCategory(v);
                    }}
                    content={v}
                    mode={selectedCategory === v ? "active" : "inactive"}
                  />
                </Grid>
              ))}
            </Grid>
          </animated.div>}
          
        </>
      {/* )} */}
    </>
  );
}
