import { useContext, useState } from "react";
import { LayoutContext } from "../ShopLayout";
import { useSpring, animated } from "@react-spring/web";
import { dummyAddress, dummyCart, dummyOrders } from "../../Shared/Constants";
import "./OrdersContent.css";
import { AddressCard } from "../../Shared/AddressCard";
import { ProductCartButtonSm } from "../../Shared/ProductCartButtonSm";
import { OrdersCard } from "../../Shared/OrdersCard";
export function OrdersContent() {
  const { width, orders } = useContext(LayoutContext);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const springs = useSpring({
    from: { x: -50 },
    to: { x: 0 },
  });

  // const [listProducts, setListProducts] = useState(dummyProducts.products);

  // useEffect(() => {
  //   console.log(dummyProducts.products.filter((v) => v.categories.includes(selectedCategory)));
  //   setListProducts(dummyProducts.products.filter((v) => v.categories.includes(selectedCategory)))

  // }, [selectedCategory])

  return (
    <>
      <animated.div
        className={"ordersContent-root"}
        style={{
          // backgroundColor: "red",
          width: width > 570 ? "60vw" : "90vw",
          display: "inline-flex",
          marginLeft: "5vw",
          marginTop: 30,
          height: width > 570 ? "70vh" : "50vh",
          position: width > 570 ? "fixed" : null,
          ...springs,
        }}
      >
        <OrdersCard items={orders} />
      </animated.div>
      {width < 570 && (
        <div
          style={{
            // backgroundColor: "red",
            marginTop: 30,
            // marginLeft: width < 570 && -20,
            width: "100vw",
            textAlign: "center",
          }}
        >

        </div>
      )}
    </>
  );
}
