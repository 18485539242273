// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.addressModal {
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
      scrollbar-width: none;  /* Firefox */
    overflow: auto;
    display: none;
  }
  
  .addressModal::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
  }`, "",{"version":3,"sources":["webpack://./src/components/Shop/Cart/AddressModal.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB,GAAG,0BAA0B;MACnD,qBAAqB,GAAG,YAAY;IACtC,cAAc;IACd,aAAa;EACf;;EAEA;IACE,aAAa,GAAG,sBAAsB;EACxC","sourcesContent":[".addressModal {\r\n    -ms-overflow-style: none;  /* Internet Explorer 10+ */\r\n      scrollbar-width: none;  /* Firefox */\r\n    overflow: auto;\r\n    display: none;\r\n  }\r\n  \r\n  .addressModal::-webkit-scrollbar { \r\n    display: none;  /* Safari and Chrome */\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
