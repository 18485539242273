import { findValueByRange } from "../Shared/Constants";

export const adjustCart = ({ cart, products }) => {
  let productIds = products.map((product) => product.productId);
  let updatedCart = cart.filter((v) => productIds.includes(v.productId));
  return updatedCart;
};

export const generateOrderId = (userId) => {
  userId = userId.replace(".", "").toUpperCase();
  let date = Date.now().toString().slice(0, 9);
  let usr = Math.ceil(userId.split("@")[0].length / 2);
  if (usr >= 3) {
    usr = userId.slice(0, 3);
  } else {
    usr = userId.slice(0, usr);
  }
  return `${usr}-${date}`;
};

const getPriceAndName = (products, productId) => {
  const currentProduct = products.filter(
    (product) => product.productId === productId
  )[0];
  if (currentProduct) {
    return {
      name: currentProduct.name,
      price: currentProduct.price,
    };
  } else {
    return null;
  }
};

// const getShipping = (products, pin) => {
//   const shippingValues = findValueByRange(parseInt(pin.toString().slice(0, 3)));
//   const perGramShipping = shippingValues / 1000;
//   let weight = 0;

//   products.map((product) => {
//     let defaultWeight = parseInt(product.defaultWeight);
//     let hundreds = defaultWeight % 1000;
//     let difference = 1000 - hundreds;
//     let accumulatedWeight = defaultWeight + difference;
//     weight += Math.ceil(product.quantity / product.bunch) * accumulatedWeight;
//   });
//   return weight * perGramShipping;
// };
const getShipping = (products, pin) => {
  const shippingValues = findValueByRange(parseInt(pin.toString().slice(0, 3)));
  const perGramShipping = shippingValues / 1000;
  let weight = 0;

  products.map((product) => {
    let defaultWeight = parseInt(product.defaultWeight);
      weight += Math.ceil(product.quantity / product.bunch) * defaultWeight;   
  });
  let hundreds = weight % 1000;
  if(hundreds !== 0) {
    let difference = 1000 - hundreds;
    let accumulatedWeight = weight + difference
    return accumulatedWeight * perGramShipping;
  } else {
    let accumulatedWeight = weight + 0
    return accumulatedWeight * perGramShipping;
  }

};

export const getProductsAndTotal = (cart, products, pin) => {
  const productIDs = cart.map((v) => v.productId);
  const inCartProducts = products
    .filter((product) => productIDs.includes(product.productId))
    .map((v) => {
      const cartQuantity = cart.filter((cv) => cv.productId === v.productId)[0]
        .quantity;
      return {
        ...v,
        quantity: cartQuantity,
      };
    });
  const cartProducts = cart.map((v) => {
    const productWithPriceAndName = getPriceAndName(products, v.productId);
    if (productWithPriceAndName) {
      return {
        id: v.productId,
        quantity: v.quantity,
        price: productWithPriceAndName.price,
        name: productWithPriceAndName.name,
      };
    }
  });
  let total = 0;
  cartProducts.map((v) => {
    if(v?.price)
      total += parseFloat(v.price) * parseInt(v.quantity);
  });
  return {
    cart: cartProducts,
    total: total,
    shipping: pin !== "no" ? getShipping(inCartProducts, pin) : 0,
  };
};
