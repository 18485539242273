import { createContext, useContext, useEffect, useState } from "react";
import { AllProducts } from "./Content/AllProducts";
import { SideNav } from "./SideNav/SideNav";
import { CartButton } from "../Shared/CartButton";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import PaymentIcon from "@mui/icons-material/Payment";
import { CartNav } from "./Cart/CartNav";
import AddIcon from "@mui/icons-material/Add";
import "./ShopLayout.css";
import { CartContent } from "./Cart/CartContent";
import { AddressContent } from "./Cart/AddressContent";
import { ProductImage } from "./ProductDetails/ProductImage";
import { ProductContent, addToCartAPI } from "./ProductDetails/ProductContent";
import { dummyProducts, paymentOptions } from "../Shared/Constants";
import { OrdersContent } from "./Orders/OrdersContent";
import { OrdersNav } from "./Orders/OrdersNav";
import { NavbarHomeComponent } from "../Nav/NavHome";
import { AdminNav } from "./Admin/AdminNav";
import { AdminContent } from "./Admin/AdminContent";
import { Alert, IconButton, Snackbar } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import makeApiRequest from "../Utils/api";
import { generateOrderId, getProductsAndTotal } from "../Utils/appUtils";
import { OrdersLoading } from "./Orders/OrdersLoading";
import { colors } from "../Shared/Constants";
import { isMobile } from "react-device-detect";
import Lottie from "lottie-react";
import animationData from '../../assets/loadingAnimation.json';
import Dexie from "dexie";
import GatewaySync from "./Admin/GatewaySync";
import { AppContext } from "../../App";
import SetGateWay from "./Admin/SetGateWay";

export const cartAPI = async (
  setSnackSeverity,
  setOpenSnackbar,
  setOpenSnackbarContent,
  setCart
) => {
  const userDetails = JSON.parse(localStorage.getItem("user"));
  try {
    if (userDetails?.email) {
      let res = await makeApiRequest(
        "POST",
        { userId: userDetails?.email },
        "/cart/get",
        setSnackSeverity,
        setOpenSnackbar,
        setOpenSnackbarContent,
        false
      );

      if (res?.status === 200) {
        if (res?.cart) {
          setCart(res?.cart?.cart);
        }
      }
      if (res?.status === 201) {
        setCart([]);
      }
    }
  } catch (e) {
    console.log(e);
  }
};

export const addressAPI = async (
  setSnackSeverity,
  setOpenSnackbar,
  setOpenSnackbarContent,
  setAddress
) => {
  try {
    const userDetails = JSON.parse(localStorage.getItem("user"));
    if (userDetails?.email) {
      let res = await makeApiRequest(
        "POST",
        { userId: userDetails?.email },
        "/address/get",
        setSnackSeverity,
        setOpenSnackbar,
        setOpenSnackbarContent,
        false
      );

      if (res?.status === 200) {
        if (res?.address) {
          setAddress(res?.address);
        }
      }
    }
  } catch (e) {
    console.log(e);
  }
};

export const ordersAPI = async (
  setSnackSeverity,
  setOpenSnackbar,
  setOpenSnackbarContent,
  setOrders
) => {
  try {
    const userDetails = JSON.parse(localStorage.getItem("user"));
    if (userDetails?.email) {
      let res = await makeApiRequest(
        "POST",
        { userId: userDetails?.email },
        "/orders/get",
        setSnackSeverity,
        setOpenSnackbar,
        setOpenSnackbarContent,
        false
      );

      if (res?.status === 200) {
        if (res?.orders) {
          setOrders(res?.orders);
        }
      }
    }
  } catch (e) {
    console.log(e);
  }
};
export const createOrder = async ({
  userDetails,
  address,
  setSnackSeverity,
  setOpenSnackbar,
  setOpenSnackbarContent,
  setCart,
  setSidePage,
  selectedProduct,
  setAddress,
  setOrders,
  setOrderId,
  setSubPage,
  orders,
  products,
  cart,
}) => {
  try {
    if (userDetails?.email) {
      const order_id = generateOrderId(userDetails?.email);
      let productsInCart = getProductsAndTotal(
        cart,
        products,
        address.filter((v) => v.default)[0].pin
      );
      const reqBody = {
        userId: userDetails?.email,
        orderId: order_id,
        products: productsInCart.cart,
        total: productsInCart.total,
        status: "Order Recieved",
        paymentStatus: "Processing Payment",
        box: "true",
        shipping: productsInCart.shipping,
        address: address.filter((v) => v.default)[0],
      };
      try {
        let res = await makeApiRequest(
          "POST",
          {
            email: userDetails?.email,
            phone: address.filter((v) => v.default)[0].phone,
            order_id: order_id,
            amount:
              parseFloat(productsInCart.shipping) +
              parseFloat(productsInCart.total),
          },
          "/orders/exec",
          setSnackSeverity,
          setOpenSnackbar,
          setOpenSnackbarContent,
          false
        );

        if (res?.status === 200) {
          if (res?.code === 200) {
            let res1 = await makeApiRequest(
              "POST",
              {
                ...reqBody,
                paymentLink: res?.data?.payment_link
                  ? res?.data?.payment_link
                  : "",
                tracking: ""
              },
              "/orders/add",
              setSnackSeverity,
              setOpenSnackbar,
              setOpenSnackbarContent,
              false
            );

            if (res1?.status === 200) {
              setCart([]);
              await addToCartAPI({
                recalled: false,
                setSaving: () => { },
                setCart: setCart,
                setSidePage: setSidePage,
                setSnackSeverity: setSnackSeverity,
                setOpenSnackbar: setOpenSnackbar,
                cart: [],
                operator: "-",
                selectedProduct: selectedProduct,
                setIsUserLoggedIn: () => { },
                setOpenSnackbarContent: setOpenSnackbarContent,
                setSubPage: setSubPage,
                setUserDetails: () => { },
                fromCart: true,
                isPlacedOrder: true,
                reload: false,
                setAddress: setAddress,
                setOrders: setOrders,
              });

              let currentOrders = orders;
              currentOrders.unshift(reqBody);
              setOrders(currentOrders);
              if (res?.data?.payment_link) {
                // setSidePage("Loading");
                // setSubPage("loading");
                // setOrderId(order_id);
                localStorage.setItem("orderId", order_id);
                window.location.href = res?.data?.payment_link;
                // window.open(res?.data?.payment_link, "_blank");
              }
            } else if (res1?.status !== 200) {
              console.log(res1);
            }
          } else {
            setOpenSnackbarContent("Issues in placing the order");
            setSnackSeverity("warning");
            setOpenSnackbar(true);
          }
        }
      } catch (e) {
        console.log(e);
      }
    }
  } catch (e) {
    console.log(e);
  }
};

export const createOrder_upi = async ({
  userDetails,
  address,
  setSnackSeverity,
  setOpenSnackbar,
  setOpenSnackbarContent,
  setCart,
  setSidePage,
  selectedProduct,
  setAddress,
  setOrders,
  setOrderId,
  setSubPage,
  orders,
  products,
  cart,
  upiMethod
}) => {
  try {
    // if (isMobile) {
    if (userDetails?.email) {
      const order_id = generateOrderId(userDetails?.email);
      let productsInCart = getProductsAndTotal(
        cart,
        products,
        address.filter((v) => v.default)[0].pin
      );
      const uniqueness =  await makeApiRequest(
        "GET",
        {
        },
        "/orders/getUniqueID",
        setSnackSeverity,
        setOpenSnackbar,
        setOpenSnackbarContent,
        false
      );
      if(uniqueness?.code) {

        const amountToPay = (parseFloat(productsInCart.total) + parseFloat(productsInCart.shipping)).toString() +"."+uniqueness?.code;
        localStorage.setItem("amount", uniqueness?.code);
        localStorage.setItem("startTime", Date.now())
        const reqBody = {
          userId: userDetails?.email,
          orderId: order_id,
          products: productsInCart.cart,
          total: productsInCart.total,
          status: "Order Recieved",
          paymentStatus: "Processing Payment",
          box: "true",
          shipping: productsInCart.shipping,
          address: address.filter((v) => v.default)[0],
        };
        try {
          let paymentLink = ``;
          let res = await makeApiRequest(
            "POST",
            {
              email: userDetails?.email,
              phone: address.filter((v) => v.default)[0].phone,
              order_id: order_id,
              amount: 
                parseFloat(productsInCart.shipping) +
                parseFloat(productsInCart.total),
            },
            "/orders/exec",
            setSnackSeverity,
            setOpenSnackbar,
            setOpenSnackbarContent,
            false
          );
          if(res?.code === 200) {
            paymentLink = res?.data?.payment_link;
            localStorage.setItem("link", paymentLink);
          }
          // const links = {
          //   gPayLink: `tez://upi/pay?pa=kev@axisb&pn=Jimaquarium%20DM&mc=${order_id}&tid=${order_id}&tr=${order_id}&tn=Payment%20for%20${order_id}&am=${amountToPay}&cu=INR`,
          //   phonepeLink: `phonepe://upi/pay?pa=kev@axisb&pn=Jimaquarium%20DM&mc=${order_id}&tid=${order_id}&tr=${order_id}&tn=Payment%20for%20${order_id}&am=${amountToPay}&cu=INR`,
          //   paytmLink: `paytm://upi/pay?pa=kev@axisb&pn=Jimaquarium%20DM&mc=${order_id}&tid=${order_id}&tr=${order_id}&tn=Payment%20for%20${order_id}&am=${amountToPay}&cu=INR`,
          //   upiLink: `upi://pay?pa=kev@axisb&pn=Jimaquarium%20DM&mc=${order_id}&tid=${order_id}&tr=${order_id}&tn=Payment%20for%20${order_id}&am=${amountToPay}&cu=INR`
          // }
  
          // switch (upiMethod) {
          //   case "Google Pay":
          //     paymentLink = links.gPayLink;
          //     break;
          //   case "PhonePe":
          //     paymentLink = links.phonepeLink;
          //     break;
          //   case "PayTM":
          //     paymentLink = links.paytmLink;
          //     break;
          //   default:
          //     paymentLink = links.upiLink;
          // }
          let res1 = await makeApiRequest(
            "POST",
            {
              ...reqBody,
              paymentLink: paymentLink,
              tracking: ""
            },
            "/orders/add",
            setSnackSeverity,
            setOpenSnackbar,
            setOpenSnackbarContent,
            false
          );
  
          if (res1?.status === 200) {
            setCart([]);
            await addToCartAPI({
              recalled: false,
              setSaving: () => { },
              setCart: setCart,
              setSidePage: setSidePage,
              setSnackSeverity: setSnackSeverity,
              setOpenSnackbar: setOpenSnackbar,
              cart: [],
              operator: "-",
              selectedProduct: selectedProduct,
              setIsUserLoggedIn: () => { },
              setOpenSnackbarContent: setOpenSnackbarContent,
              setSubPage: setSubPage,
              setUserDetails: () => { },
              fromCart: true,
              isPlacedOrder: true,
              reload: false,
              setAddress: setAddress,
              setOrders: setOrders,
            });
  
            let currentOrders = orders;
            currentOrders.unshift(reqBody);
            setOrders(currentOrders);
            console.log("Setting local", paymentLink);
            if (paymentLink) {
              // setSidePage("Loading");
              // setSubPage("loading");
              // setOrderId(order_id);
              localStorage.setItem("orderId", order_id);
              localStorage.setItem("link", paymentLink);
              localStorage.setItem("mode", upiMethod)
              setSidePage('Loading');
              setSubPage('loading');
  
  
              if (isMobile)
              window.open(paymentLink, "_blank");
                // window.location.href = paymentLink;
            }
          }
        } catch (e) {
          console.log(e);
        }
      } else {
        throw new Error("Can't fetch Amount");
      }

    }
  } catch (e) {
    console.log(e);
  }
};

export const LayoutContext = createContext(null);
export function ShopLayout() {
  const [subPage, setSubPage] = useState("allProducts");
  const [sidePage, setSidePage] = useState("Categories");
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [selectedPage, setSelectedPage] = useState("Cart");
  const [selectedProduct, setSelectedProduct] = useState(
    dummyProducts.products[0]
  );
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [cart, setCart] = useState([]);
  const [address, setAddress] = useState([]);
  const [orders, setOrders] = useState([]);

  const [openSnackBarContent, setOpenSnackbarContent] = useState("");
  const [snackSeverity, setSnackSeverity] = useState("success");
  const [openSnackBar, setOpenSnackbar] = useState(false);
  const [fabContent, setFabContent] = useState("");
  const [fabIcon, setFabIcon] = useState(<ShoppingCartIcon />);
  const [appLoading, setAppLoading] = useState(true);
  const [width, setWidth] = useState(window.innerWidth);
  const [total, setTotal] = useState(0);
  const [orderId, setOrderId] = useState("");
  const [upiMethod, setUpiMethod] = useState("Google Pay");
  const [syncing, setSyncing] = useState(false);
  const [prodcutLoading, setProductLoading] = useState(false);
  const [loadingCache, setLoadingCache] = useState(true);
  let localOrderId = localStorage.getItem("orderId");

  window.addEventListener("resize", () => {
    setWidth(window.innerWidth);
  });
  const userDetails = JSON.parse(localStorage.getItem("user"));
  const db = new Dexie("productDB");
  db.version(1).stores({
    product: '++id, &productId, product'
  });
  async function backgroundSyncProduct() {
    setSyncing(true);
    try {
      const updatedProducts = await makeApiRequest(
        "GET",
        {},
        "/products/all",
        setSnackSeverity,
        setOpenSnackbar,
        setOpenSnackbarContent,
        false
      );
      let isUpdated = false;
      if (updatedProducts?.status === 200) {
        if (updatedProducts?.products) {
          await db.transaction('rw', db.product, async () => {
            for (const updatedProduct of updatedProducts?.products) {
              let existingProduct = await db.product
                .where('productId')
                .equals(updatedProduct.productId)
                .first();
              if (existingProduct) {
                const currentId = existingProduct.id;
                const updatedProductDate = new Date(updatedProduct.updated).getTime();
                const existingProductDate = new Date(existingProduct.updated).getTime();
                if (updatedProductDate > existingProductDate) {
                  existingProduct = { ...updatedProduct, id: currentId };
                  console.log("Updating local cache");
                  isUpdated = true;
                  await db.product.update(currentId, existingProduct);
                }
              } else {
                // Product doesn't exist -> Add as new
                console.log("Updating local cache");
                isUpdated = true;
                await db.product.add(updatedProduct);
              }
            }
            const cachedProducts = await db.table('product').toArray();
            for (const cachedProduct of cachedProducts) {
              
              let existingProduct = updatedProducts?.products?.findIndex(v => v.productId === cachedProduct.productId)
              if (existingProduct < 0 ) {
                console.log("Updating local cache");
                isUpdated = true;
                await db.product.delete(cachedProduct.id);
              }
            }
          });
        }
        




        console.log("isUpdated", isUpdated);
        if (isUpdated) {
          const cachedProducts = await db.table('product').toArray();
          setProducts(cachedProducts);
        }
      }
    } catch (error) {
      console.error('Background sync error:', error);
    } finally {
      setSyncing(false);
    }
  }




  const productsAPI = async () => {
    const cachedProducts = await db.table('product').toArray();
    if (cachedProducts.length > 0) {
      setLoadingCache(true);
      console.log("Cashed items");
      let categoriesArray = [];
      const tempCategoriesArray = cachedProducts.map(
        (object) => object.categories
      );
      categoriesArray = [...new Set(tempCategoriesArray)];
      backgroundSyncProduct();
      setCategories(categoriesArray);
      setProducts(cachedProducts);
      setLoadingCache(false);
    } else {
      setLoadingCache(false);
      setProductLoading(true);
      try {
        let res = await makeApiRequest(
          "GET",
          {},
          "/products/all",
          setSnackSeverity,
          setOpenSnackbar,
          setOpenSnackbarContent,
          false
        );

        if (res?.status === 200) {
          let categoriesArray = [];
          if (res?.products) {
            console.log(res.products);
            const product = res.products;
            await db.table('product').bulkAdd(product);
            // const cachedProducts = await db.table('products').toArray();
            // console.log(cachedProducts);
            const tempCategoriesArray = res?.products?.map(
              (object) => object.categories
            );
            categoriesArray = [...new Set(tempCategoriesArray)];
          }
          setCategories(categoriesArray);
          setProducts(res?.products);
        }
      } catch (e) {
        console.log(e);
      } finally {
        setProductLoading(false);
      }
    }

  };

  const setInitialTotal = () => {
    setTotal(getProductsAndTotal(cart, products, "no").total);
  };

  useEffect(() => {
    if (products.length === 0) {
      setAppLoading(true);
      Promise.all([
        productsAPI(),
        cartAPI(
          setSnackSeverity,
          setOpenSnackbar,
          setOpenSnackbarContent,
          setCart
        ),
        addressAPI(
          setSnackSeverity,
          setOpenSnackbar,
          setOpenSnackbarContent,
          setAddress
        ),
        ordersAPI(
          setSnackSeverity,
          setOpenSnackbar,
          setOpenSnackbarContent,
          setOrders
        ),
      ])
        .then(() => {
          setAppLoading(false);
          if (localOrderId) {
            setSubPage("loading");
            setSidePage("Loading");
          }
        })
        .catch((e) => {
          setInitialTotal();
          setAppLoading(false);
        });
    } else {
      setAppLoading(false);
    }
  }, []);

  useEffect(() => {
    if (subPage === "cart") {
      setFabContent("Select Address");
      setFabIcon(null);
    } else if (subPage === "address") {
      setFabContent("Checkout");
      setFabIcon(<PaymentIcon />);
    } else if (sidePage === "Admin") {
      setFabContent("");
      setFabIcon(null);
    } else {
      setFabContent("");
      setFabIcon(<ShoppingCartIcon />);
    }
  }, [subPage]);

  const handleClose = () => {
    setOpenSnackbar(false);
    setOpenSnackbarContent("");
  };


  return (
    <>
      {prodcutLoading ? (
        <>

          <div style={{ textAlign: "center", paddingTop: 30 }}>
            <div style={{ width: width > 570 ? "40vw" : "90vw", height: width > 570 ? "40vw" : "90vw", display: "inline-block", }}>
              <Lottie animationData={animationData} autoPlay loop
                rendererSettings={{ preserveAspectRatio: 'xMidYMid slice', }} />

            </div>
            <div>
              <span className="settingUpText" style={{ color: colors.primary }}>
                {`Setting up the Application`}
              </span>
            </div>
            <div>
              <span className="settingUpSecondaryText" style={{ color: colors.primary }}>
                {`This is a one-time process`}
              </span>
            </div>
          </div>


        </>
      ) : (
        <LayoutContext.Provider
          value={{
            subPage,
            setSubPage,
            sidePage,
            setSidePage,
            selectedCategory,
            setSelectedCategory,
            width,
            selectedPage,
            setSelectedPage,
            selectedProduct,
            setSelectedProduct,
            setOpenSnackbar,
            setOpenSnackbarContent,
            setSnackSeverity,
            products,
            setProducts,
            categories,
            setCategories,
            cart,
            setCart,
            address,
            setAddress,
            orders,
            setOrders,
            total,
            setTotal,
            orderId,
            setOrderId,
            upiMethod,
            setUpiMethod,
            loadingCache
          }}
        >
          <NavbarHomeComponent />
          <Snackbar
            open={openSnackBar}
            autoHideDuration={3000}
            onClose={handleClose}
          >
            <Alert
              onClose={handleClose}
              severity={snackSeverity}
              variant="filled"
              sx={{ width: "100%" }}
            >
              {openSnackBarContent}
            </Alert>
          </Snackbar>
          <div className="cart">
            {!loadingCache && <CartButton
              action={() => {
                if (subPage === "cart") {
                  setSelectedPage("Address");
                  setSubPage("address");
                } else if (subPage === "address") {
                  if (address.length === 0) {
                    setOpenSnackbarContent("Add a Default Address");
                    setSnackSeverity("warning");
                    setOpenSnackbar(true);
                  } else if (cart.length === 0) {
                    setOpenSnackbarContent("Your shopping cart is empty");
                    setSnackSeverity("warning");
                    setOpenSnackbar(true);
                  } else {
                    if (paymentOptions.cashfree) {
                      createOrder({
                        address: address,
                        selectedProduct: selectedProduct,
                        setAddress: setAddress,
                        setCart: setCart,
                        setOpenSnackbar: setOpenSnackbar,
                        setOpenSnackbarContent: setOpenSnackbarContent,
                        setOrderId: setOrderId,
                        setOrders: setOrders,
                        setSidePage: setSidePage,
                        setSnackSeverity: setSnackSeverity,
                        userDetails: userDetails,
                        setSubPage: setSubPage,
                        orders: orders,
                        products: products,
                        cart: cart,
                        tracking: "",
                      });
                    } else {
                      // localStorage.removeItem("status")
                      createOrder_upi({
                        address: address,
                        selectedProduct: selectedProduct,
                        setAddress: setAddress,
                        setCart: setCart,
                        setOpenSnackbar: setOpenSnackbar,
                        setOpenSnackbarContent: setOpenSnackbarContent,
                        setOrderId: setOrderId,
                        setOrders: setOrders,
                        setSidePage: setSidePage,
                        setSnackSeverity: setSnackSeverity,
                        userDetails: userDetails,
                        setSubPage: setSubPage,
                        orders: orders,
                        products: products,
                        cart: cart,
                        tracking: "",
                        upiMethod: upiMethod
                      });
                    }
                  }
                } else if (sidePage === "Admin") {
                } else {
                  setSidePage("Menu");
                  setSelectedPage("Cart");
                  setSubPage("cart");
                }
              }}
              content={fabContent}
              mode={"active"}
              icon={fabIcon}
            />}

          </div>
          {sidePage === "Categories" && <SideNav />}
          {sidePage === "Menu" && <CartNav />}
          {sidePage === "Product" && <ProductImage />}
          {sidePage === "Orders" && <OrdersNav />}
          {sidePage === "Admin" && <AdminNav />}
          {/* {sidePage === "Loading" && <div />} */}
          {sidePage === "Loading" && <div />}
          <div style={{ display: "inline" }}>
            {subPage === "allProducts" && <AllProducts />}
            {subPage === "cart" && <CartContent />}
            {subPage === "address" && <AddressContent />}
            {subPage === "product" && <ProductContent />}
            {subPage === "orders" && <OrdersContent />}
            {subPage === "admin" && <AdminContent />}
            {subPage === "gateway" && <GatewaySync />}
            {/* {subPage === "payment" && <PaymentContent />} */}
            {subPage === "loading" && <OrdersLoading />}
          </div>
          {(syncing || appLoading) && <div className="color-flow" style={{ height: 5, width: "100%", position: "fixed", bottom: 0 }}></div>
          }
        </LayoutContext.Provider>
      )}
    </>
  );
}
