// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ordersContent-root {
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
      scrollbar-width: none;  /* Firefox */
    overflow: auto;
  }
  
  .ordersContent-root::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
  }

  .order_header_title {
    font-family: "M PLUS Rounded 1c", sans-serif;
    font-weight: 300;
    font-size: 16px;
  }

  .button-loader {
    display: flex;
    gap: .25rem;
  }
  .button-loader > div {
    width: .7rem;
    height: .7rem;
    background-color: #221428;
    border-radius: 50%;
    animation: 1.2s infinite ease-in-out load;
  }
  
  .button-loader  div:nth-child(1) {
    animation-delay: -0.32s;
  }
  .button-loader  div:nth-child(2) {
    animation-delay: -0.16s;
  }

  @keyframes load {
    0%,80%,100%{
      transform: scale(0);
    }
    40% {
      transform: scale(1);
    }
  }`, "",{"version":3,"sources":["webpack://./src/components/Shop/Admin/AdminContent.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB,GAAG,0BAA0B;MACnD,qBAAqB,GAAG,YAAY;IACtC,cAAc;EAChB;;EAEA;IACE,aAAa,GAAG,sBAAsB;EACxC;;EAEA;IACE,4CAA4C;IAC5C,gBAAgB;IAChB,eAAe;EACjB;;EAEA;IACE,aAAa;IACb,WAAW;EACb;EACA;IACE,YAAY;IACZ,aAAa;IACb,yBAAyB;IACzB,kBAAkB;IAClB,yCAAyC;EAC3C;;EAEA;IACE,uBAAuB;EACzB;EACA;IACE,uBAAuB;EACzB;;EAEA;IACE;MACE,mBAAmB;IACrB;IACA;MACE,mBAAmB;IACrB;EACF","sourcesContent":[".ordersContent-root {\n    -ms-overflow-style: none;  /* Internet Explorer 10+ */\n      scrollbar-width: none;  /* Firefox */\n    overflow: auto;\n  }\n  \n  .ordersContent-root::-webkit-scrollbar { \n    display: none;  /* Safari and Chrome */\n  }\n\n  .order_header_title {\n    font-family: \"M PLUS Rounded 1c\", sans-serif;\n    font-weight: 300;\n    font-size: 16px;\n  }\n\n  .button-loader {\n    display: flex;\n    gap: .25rem;\n  }\n  .button-loader > div {\n    width: .7rem;\n    height: .7rem;\n    background-color: #221428;\n    border-radius: 50%;\n    animation: 1.2s infinite ease-in-out load;\n  }\n  \n  .button-loader  div:nth-child(1) {\n    animation-delay: -0.32s;\n  }\n  .button-loader  div:nth-child(2) {\n    animation-delay: -0.16s;\n  }\n\n  @keyframes load {\n    0%,80%,100%{\n      transform: scale(0);\n    }\n    40% {\n      transform: scale(1);\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
