import { useContext, useEffect, useState } from "react"
import bg from '../../../assets/background.webp';
import { colors } from '../../Shared/Constants'
import makeApiRequest from "../../Utils/api";
import { ProductCartButtonMd } from "../../Shared/ProductCartButtonMd";

export default function SetGateWay() {
    const [codeValue, setCodeValue] = useState(null);
    const [error, setError] = useState('');


    const setCode = async (code) => {
        if (code) {
            let res = await makeApiRequest(
                "POST",
                { code: code },
                "/orders/setToken",
                () => { },
                () => { },
                () => { }
            );
            if (res.code === 200) {
                if (res.message === "Token set successfully") {
                    window.location.href = window.location.origin
                } else {
                    console.log("failed");
                    setError(res.error)
                }
            }
        } else {

            // setCode();
        }

    }
    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        setCodeValue(queryParams.get('code'));
        setCode(queryParams.get('code'));
    }, [])
    return (<>
        <div style={{ padding: 20, backgroundImage: `url(${bg})`, color: "white", height: "100vh" }}>

            {codeValue}
            <div style={{ marginTop: 20 }}>
                <span style={{ color: colors.primary }}>
                    setting code...
                </span>
            </div>
            <div style={{ marginTop: 20 }}>
                <span style={{ color: colors.warning }}>
                    {JSON.stringify(error?.response?.data)}
                </span>
            </div>
            <div style={{ marginTop: 30 }}>
                <ProductCartButtonMd
                    action={() => {
                        window.location.href = window.location.origin
                    }}
                    mode={"activen"}
                    content={"Go Back"}

                />
            </div>



        </div>
    </>)
}