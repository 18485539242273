import { colors } from "./Constants";
import './Shared.css';

export function ProductCartButtonMd({mode, content, icon=null, style = {}, action}) {
    const borderColor = mode === "active" ? colors.primary : 'white';
    const backgroundColor =  mode === "active" ? colors.primary : '';
    const textColor =  mode === "active" ? '' : 'white';
    return(
        <>
            <div onClick={action} style={{
                ...style,
                backgroundColor: backgroundColor,
                paddingLeft: 30,
                paddingRight: 30,
                paddingTop: 10,
                paddingBottom: 10,
                border: `solid ${borderColor} 1px`,
                cursor: "pointer",
                // borderRadius: 5
            }}>
                <span className="product_content_cart" style={{color: textColor}}>{content} {icon ? icon : ''}</span>
            </div>
        </>
    )
}

