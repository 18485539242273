import { useContext } from "react";
import { Grid } from "@mui/material";
import { LayoutContext } from "../ShopLayout";
import { useSpring, animated } from "@react-spring/web";
import { CategoryButtonMd } from "../../Shared/CategoryButtonMd";
import { CategoryButtonSm } from "../../Shared/CategoryButtonSm";

export function CartNav() {
  const { width, setSidePage, selectedPage, setSelectedPage, setSubPage } =
    useContext(LayoutContext);
  const springs = useSpring({
    from: { x: -50 },
    to: { x: 0 },
  });
  const sideItems = [
    {
      name: "Back",
      action: () => {
        setSidePage("Categories");
        setSubPage("allProducts");
      },
    },
    {
      name: "Cart",
      action: () => {
        setSelectedPage("Cart");
        setSubPage("cart")
      },
    },
    {
      name: "Address",
      action: () => {
        setSelectedPage("Address");
        setSubPage("address")
      },
    },
  ];

  // useEffect(() => {
  //   console.log(selectedCategory);
  // }, [selectedCategory])

  //   useEffect(() => {
  //     console.log(width);
  //   }, [width]);

  return (
    <>
      {width > 575 ? (
        <animated.div
          style={{
            display: "inline-flex",
            width: "20vw",
            marginLeft: 30,
            marginTop: 60,
            ...springs,
          }}
        >
          <div>
            {sideItems.map((v, i) => {
              return (
                <>
                  <CategoryButtonMd
                    action={v.action}
                    content={v.name}
                    mode={selectedPage === v.name ? "active" : "inactive"}
                  />
                  <div style={{ height: 10 }}></div>
                </>
              );
            })}
          </div>
        </animated.div>
      ) : (
        <>
          <animated.div
            style={{
              marginLeft: 30,
              marginTop: 60,
              ...springs,
            }}
          >
            <Grid container rowSpacing={3} columnSpacing={2}>
              {sideItems.map((v, i) => {
                return (
                  <>
                    <Grid item>
                      <CategoryButtonSm
                        action={v.action}
                        content={v.name}
                        mode={selectedPage === v.name ? "active" : "inactive"}
                      />
                    </Grid>
                  </>
                );
              })}
            </Grid>
          </animated.div>
        </>
      )}
    </>
  );
}
