import { useContext, useEffect, useState } from "react";
import { LayoutContext } from "../ShopLayout";
import { useSpring, animated } from "@react-spring/web";
import { colors, dummyAddress, dummyCart } from "../../Shared/Constants";
import "./CartContent.css";
import { AddressCard } from "../../Shared/AddressCard";
import { ProductCartButtonSm } from "../../Shared/ProductCartButtonSm";
import AddressModal from "./AddressModal";
import makeApiRequest from "../../Utils/api";
import { getProductsAndTotal } from "../../Utils/appUtils";
import PaymentContent from "../Payment/PaymentContent";
import { isMobile } from "react-device-detect";
import './AddressModal.css'
export function AddressContent() {
  const { width, address, setAddress, setSnackSeverity,
    setOpenSnackbar,
    setOpenSnackbarContent, total, setSelectedPage, cart, products, setTotal } = useContext(LayoutContext);
  const [isOpenModal, setIsOpenModal] = useState(address.length === 0);
  const [addressId, setAddressId] = useState("1");
  const [shipping, setShipping] = useState(0);
  const [selectedAddress, setSelectedAddress] = useState({
    name: "",
    line1: "",
    line2: "",
    city: "",
    state: "",
    pin: "",
    default: true,
  });

  const userDetails = JSON.parse(localStorage.getItem("user"));
  const springs = useSpring({
    from: { x: width <570 ? -50 : 0 },
    to: { x: 0 },
  });

  useEffect(() => {
    setSelectedPage("Address");
    setTotal(getProductsAndTotal(cart, products, "no").total);
  }, [])
  const setDefault = async (ad) => {

    let addressList = address;
    let addedAddressIndex = addressList.findIndex(v => v.id === ad.id);
    addressList = addressList.map(v => { return ({ ...v, default: false }); });
    if (addedAddressIndex !== -1) {
      addressList[addedAddressIndex] = { ...ad, default: true };
    }
    let res = await makeApiRequest(
      "POST",
      { userId: userDetails?.email, address: addressList },
      "/address/add",
      setSnackSeverity,
      setOpenSnackbar,
      setOpenSnackbarContent
    );
    if (res?.status === 200) {
      setAddress(addressList);
      setSelectedAddress({
        name: "",
        line1: "",
        line2: "",
        city: "",
        state: "",
        pin: "",
        phone: "",
        default: true,
      })
    }
  }

  return (
    <>
      <animated.div
        className={"cartContent-root addressModal"}
        style={{
          // backgroundColor: "red",
          width: width > 570 ? "50vw" : "90vw",
          display: "inline-flex",
          marginLeft: "5vw",
          marginTop: 30,
          height: width > 570 ? "60vh" : "40vh",
          position: width > 570 ? "fixed" : null,
           ...springs,
        }}
      >
        <AddressCard
          items={address}
          setModalOpen={setIsOpenModal}
          setSelectedAddress={setSelectedAddress}
          setAddressId={setAddressId}
          setDefault={setDefault}
          setShipping={setShipping}
        />
        {isOpenModal && <AddressModal
          id={addressId}
          open={true}
          handleClose={() => {
            setIsOpenModal(false);
          }}
          initialState={selectedAddress}
        />}
        {total !== 0 && width > 570 && <div style={{ position: "fixed", bottom: 10, }}>
          <div style={{ textAlign: "start", paddingRight: 25 }}>
            <span className="total_content_primary" style={{ color: colors.primary }}>{`Amount to Pay: ₹${parseFloat(total) + parseFloat(shipping)}`}</span>
            <div style={{ textAlign: "start", paddingRight: 20 }}>
              <span className="total_content_secondary" style={{ color: colors.primary }}>{`*Shipping includes safe packaging with Oxygen`}</span>
            </div>
          </div>
        </div>}

      </animated.div>
      {total !== 0 && (
        <>
          {width < 570 ? <>
            <div style={{ marginTop: 10  , textAlign: "end", paddingRight: 25 }}>
              <span className="total_content_primary" style={{ color: colors.primary }}>{`Amount to Pay: ₹${parseFloat(total) + parseFloat(shipping)}`}</span>

            </div>
            <div style={{ textAlign: "end", paddingRight: 20 }}>
              <span className="total_content_secondary" style={{ color: colors.primary }}>{`*Shipping includes safe packaging with Oxygen`}</span>
            </div>
            {isMobile && <PaymentContent />}
          </> :
            <>


            </>

          }



        </>

      )}
    </>
  );
}
