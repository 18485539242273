import { Container } from "@mui/material";
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import "./Home.css";
import React, { useContext, useEffect } from "react";
import { CustomButton } from "../Shared/Button";
import { AppContext } from "../../App";
import { useSpring, animated } from '@react-spring/web';
import { LayoutContext } from "../Shop/ShopLayout";

export default function Home() {
  const {setPage } = useContext(AppContext);
  const springs = useSpring({
    from: { x: -50 },
    to: { x: 0 },
  });


  // useEffect(() => {
  //   if(localOrderId) {
  //     setSidePage('Loading');
  //     setSubPage('loading');
  //   }
  // }, [localOrderId])
  return (
    <>
      <animated.div style={{height: "72.5vh", ...springs}}>
        <Container>
          <div style={{ marginTop: "17vh" }}>
            <span className="header_ul">THE AQUATIC SPECIALIST</span>
          </div>
          <div className="header_topic">Ocean Corals</div>
          <div className="header_topic" style={{ marginTop: -10 }}>
            and reptiles
          </div>
          <div>
            <span className="header_content">{`Jim Aquarium is an aquatic shop dedicated in `}</span>
          </div>
          <div>
            <span className="header_content">{`soucing the most sought after fish and tanks `}</span>
          </div>
          <div>
            <span className="header_content">{`available all over India`}</span>
          </div>
          <div style={{display: "inline-block", marginTop: 40}}>
            <CustomButton mode={'plain'} action={() => {}} content={"CONTACT US"} icon={<ArrowRightAltIcon />}/>
            <CustomButton style={{marginLeft: 15}} mode={'active'} action={() => {setPage("shopLayout")}} content={"SHOP NOW"} icon={<ArrowRightAltIcon />}/>
          </div>
        </Container>
      </animated.div>
    </>
  );
}
