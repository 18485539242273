import {
  Autocomplete,
  Box,
  Button,
  Divider,
  Modal,
  OutlinedInput,
  TextField,
} from "@mui/material";
import { useContext, useState } from "react";
import { LayoutContext, ordersAPI } from "../ShopLayout";
import Fade from "@mui/material/Fade";
import bg from "../../../assets/background.webp";
import { ProductCartButtonSm } from "../../Shared/ProductCartButtonSm";
import { colors } from "../../Shared/Constants";
import makeApiRequest from "../../Utils/api";
import './AdminContent.css'

export default function OrderUpdateModal({ open, handleClose, orderDetails }) {
  const {
    width,
    setOpenSnackbar,
    setOpenSnackbarContent,
    setSnackSeverity,
    setOrders,
  } = useContext(LayoutContext);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: width > 570 ? "40vw" : "90vw",
    boxShadow: 24,
    p: 4,
    height: "80vh",
  };
  const inputStyle = {
    // color: "red"
  };

  const orderStatuses = ["Order Recieved", "Processing","Packed", "Shipped", "Cancelled"];
  const paymentStatuses = [
    "Paid",
    "Processing Payment",
    "Payment Awaiting",
    "Payment Completed",
    "Failed",
  ];
  const defaultPaymentStatusOptions = paymentStatuses.map((string) => ({
    label: string,
  }));
  const defaultOrdertStatusOptions = orderStatuses.map((string) => ({
    label: string,
  }));

  const defaultFormState = orderDetails;
  const [saving, setSaving] = useState(false);

  const [formData, setFormData] = useState(defaultFormState);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleInputChangeAutoComplete = (e, value) => {
    setFormData({ ...formData, ["paymentStatus"]: value?.label });
  };
  const handleInputChangeAutoCompleteOrders = (e, value) => {
    setFormData({ ...formData, ["status"]: value?.label });
  };

  const handleSubmit = async (e) => {
    try {
      setSaving(true);
      e.preventDefault();
      const formDataJSON = {
        paymentStatus: formData.paymentStatus,
        status: formData.status,
        tracking: formData.tracking,
        orderId: formData.orderId,
      };
      let res = await makeApiRequest(
        "POST",
        formDataJSON,
        "/orders/update",
        setSnackSeverity,
        setOpenSnackbar,
        setOpenSnackbarContent
      );
      if (res?.status === 200) {
        if(res?.message === "Products are out of Stock") {
          setSnackSeverity("warning");
          setOpenSnackbarContent("Products Out of Stock");
          setOpenSnackbar(true);
        } else {
          await ordersAPI(
            setSnackSeverity,
            setOpenSnackbar,
            setOpenSnackbarContent,
            setOrders
          );
        }
        
        handleClose();
      }
    } catch(e) {
        setSnackSeverity("warning");
        setOpenSnackbarContent("Please try again later");
        setOpenSnackbar(true);
    } finally {
      setSaving(false);
    }
   
   
    // console.log('Form data as JSON:', formDataJSON);
  };

  return (
    <Modal
      open={open}
      //   onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Fade in={open}>
        <Box sx={{ ...style, backgroundImage: `url(${bg})` }}>
          <Box
            className="address_modal_background"
            sx={{ ...style, overflow: "auto" }}
          >
            <div>
              <span className="new_address_title">{`${formData.orderId}`}</span>
              <form onSubmit={handleSubmit}>
                

                <Autocomplete
                  onChange={handleInputChangeAutoCompleteOrders}
                  // disabled={saving}
                  defaultValue={formData.status}
                  renderOption={(props, option) => {
                    const { label } = option;
                    return (
                      <span
                        {...props}
                        style={{
                          backgroundColor: "#120a28",
                          color: "white",
                        }}
                      >
                        {label}
                      </span>
                    );
                  }}
                  disablePortal
                  id="combo-box-demo"
                  options={defaultOrdertStatusOptions}
                  sx={{ marginTop: 2 }}
                  renderInput={(params) => (
                    <TextField
                      disabled={saving}
                      onChange={handleInputChangeAutoCompleteOrders}
                      name="paymentStatus"
                      {...params}
                      label="Payment Status"
                    />
                  )}
                />

                <Autocomplete
                  onChange={handleInputChangeAutoComplete}
                  // disabled={saving}
                  defaultValue={formData.paymentStatus}
                  renderOption={(props, option) => {
                    const { label } = option;
                    return (
                      <span
                        {...props}
                        style={{
                          backgroundColor: "#120a28",
                          color: "white",
                        }}
                      >
                        {label}
                      </span>
                    );
                  }}
                  disablePortal
                  id="combo-box-demo"
                  options={defaultPaymentStatusOptions}
                  sx={{ marginTop: 2 }}
                  renderInput={(params) => (
                    <TextField
                      disabled={saving}
                      onChange={handleInputChangeAutoComplete}
                      name="paymentStatus"
                      {...params}
                      label="Payment Status"
                    />
                  )}
                />
                <OutlinedInput
                  required
                  placeholder="Total"
                  className="input_text"
                  fullWidth
                  name="total"
                  onChange={handleInputChange}
                  value={`Amount: ${
                    (parseFloat(formData.total) + parseFloat(formData.shipping))
                  }₹`}
                />
                <OutlinedInput
                  // required
                  placeholder="Tracking"
                  className="input_text"
                  fullWidth
                  name="tracking"
                  onChange={handleInputChange}
                  value={formData.tracking}
                />

                <div style={{marginTop: 20}}>
                  <div>
                  <span 
                  style={{color: colors.primary, fontSize: 20}}
                  >Products:</span>
                  </div>
                 
                  <div>
                    {formData.products.map((v) => (
                      <div style={{color: colors.primary}} >
                        <span style={{color: colors.primary}}>{`${v.name} x ${v.quantity}`}</span>
                      </div>
                    ))}
                  </div>
                </div>

                <div style={{marginTop: 20}}>
                  <span  style={{color: colors.primary, fontSize: 20}} >Address</span>
                  <div>
                    {/* {formData.address.map((v) => ( */}
                      <>
                        <div style={{color: colors.primary}}>
                          <span>{`${formData.address.name}`}</span>
                        </div>
                        <div style={{color: colors.primary}} >
                          <span>{`${formData.address.phone}`}</span>
                        </div>
                        <div style={{color: colors.primary}}>
                          <span>{`${formData.address.line1}`}</span>
                        </div>
                        <div style={{color: colors.primary}}>
                          <span>{`${formData.address.line2}`}</span>
                        </div>
                        <div style={{color: colors.primary}}>
                          <span>{`${formData.address.city}`}</span>
                        </div>
                        <div style={{color: colors.primary}}>
                          <span>{`${formData.address.pin}`}</span>
                        </div>
                        <div style={{color: colors.primary}}>
                          <span>{`${formData.address.state}`}</span>
                        </div>
                      </>
                    {/* ))} */}
                  </div>
                </div>

                <div>
                  <div style={{ textAlign: "center", marginTop: 30 }}>
                    <ProductCartButtonSm
                      action={() => {
                        if (!saving) {
                          handleClose();
                        }
                      }}
                      content={"Back"}
                      mode={"inactive"}
                      style={{
                        marginRight: 10,
                      }}
                    />
                    <Button
                      type="submit"
                      variant="contained"
                      sx={{
                        backgroundColor: colors.primary,
                        color: "black",
                        height: 42,
                      }}
                    >
                      {saving ? (
                        <div class="button-loader">
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                      ) : (
                        <span>Update</span>
                      )}
                    </Button>
                    {/* <ProductCartButtonSm
                      action={() => {}}
                      content={"Add"}
                      mode={"active"}
                      style={{
                        marginLeft: 10,
                      }}
                    /> */}
                  </div>
                </div>
               
              </form>
            </div>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
}
