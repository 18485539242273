import { useContext } from "react";
import { ProductCartButtonSm } from "../../Shared/ProductCartButtonSm";
import { LayoutContext } from "../ShopLayout";
import { useSpring, animated } from "@react-spring/web";
import "./GatewaySync.css";
import makeApiRequest from "../../Utils/api";

export default function GatewaySync() {
    const { width, setSnackSeverity,
        setOpenSnackbar,
        setOpenSnackbarContent, } = useContext(LayoutContext);
    const springs = useSpring({
        from: { x: -50 },
        to: { x: 0 },
    });

    const getAuthUrl = async () => {
        let res = await makeApiRequest(
            "GET",
            {},
            "/orders/generateToken",
            setSnackSeverity,
            setOpenSnackbar,
            setOpenSnackbarContent
        );
        if (res?.code === 200) {
            if (res?.message === "URL Generated") {
                if (res?.url)
                    window.location.href = res.url
            } else {
                setSnackSeverity("warning");
                setOpenSnackbarContent("Gateway Sync Failed")
                setOpenSnackbar(true);
            }
        }
    }
    return (<>
        <>
            <animated.div
                className={"gatewayContent-root"}
                style={{
                    // backgroundColor: "red",
                    width: width > 570 ? "60vw" : "90vw",
                    display: "inline-flex",
                    marginLeft: "5vw",
                    marginTop: 30,
                    height: width > 570 ? "70vh" : "50vh",
                    position: width > 570 ? "fixed" : null,
                    ...springs,
                }}
            >
                <div>
                    <ProductCartButtonSm action={getAuthUrl} content={"Gateway Sync"} mode={"active"} style={{ marginTop: 20, }} />
                </div>

            </animated.div>
            {width < 570 && (
                <div
                    style={{
                        // backgroundColor: "red",
                        marginTop: 30,
                        // marginLeft: width < 570 && -20,
                        width: "100vw",
                        textAlign: "center",
                    }}
                >

                </div>
            )}
        </>
    </>)
}   