import logo from "./logo.svg";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./components/Home/Home";
import { createContext, useEffect, useState } from "react";
import NavbarComponent from "./components/Nav/Nav";
import { ShopLayout } from "./components/Shop/ShopLayout";
import bg from "./assets/background.webp";
import ImageUploader from "./components/Utils/ImageUploader";
import Terms from "./components/Static/Terms";
import PrivacyPolicy from "./components/Static/Privacy";
import ContactUs from "./components/Static/Contact";
import QuickRedirect from "./components/Static/QuickRedirect";
import SetGateWay from "./components/Shop/Admin/SetGateWay";
import SubWrapper from "./components/Shared/SabWrapper";
import RedirectPay from "./components/Shared/RedirectPay";
import SabpaisaPaymentGateway from "./components/Shared/SabpaisaPaymentGateway";

export const AppContext = createContext(null);
function App() {
  let currectUser = JSON.parse(localStorage.getItem("user"));
  const [page, setPage] = useState(currectUser?.name ? "shopLayout" : "home");
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(
    currectUser?.name ? true : false,
  );
  const [userDetails, setUserDetails] = useState(
    currectUser?.name ? currectUser : {},
  );
  let localOrderId = localStorage.getItem("orderId");

  // useEffect(() => {
  //   if (navigator.userAgent.toLocaleLowerCase().includes("android")) {
  //     console.log("Android");
  //     window.location.href =
  //       "https://play.google.com/store/apps/details?id=com.jimaquarium.newui";
  //   } else {
  //     console.log(navigator.userAgent.toLocaleLowerCase());
  //   }
  // }, []);

  const MainApp = () => {
    return (
      <AppContext.Provider
        value={{
          setPage,
          isUserLoggedIn,
          setIsUserLoggedIn,
          userDetails,
          setUserDetails,
        }}
      >
        <div className="root" style={{ backgroundImage: `url(${bg})` }}>
          {page === "home" && !localOrderId && <NavbarComponent />}

          {page === "home" && !localOrderId && <Home />}
          {(page === "shopLayout" || localOrderId) && <ShopLayout />}
        </div>
      </AppContext.Provider>
    );
  };
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<MainApp />}>
          <Route index element={<MainApp />} />
        </Route>
        <Route path="/test" element={<SabpaisaPaymentGateway />}>
          <Route index element={<SabpaisaPaymentGateway />} />
        </Route>
        <Route path="/token" element={<SetGateWay />}>
          <Route index element={<SetGateWay />} />
        </Route>
        <Route path="/terms" element={<Terms />}>
          <Route index element={<Terms />} />
        </Route>
        <Route path="/privacy" element={<PrivacyPolicy />}>
          <Route index element={<PrivacyPolicy />} />
        </Route>
        <Route path="/contact" element={<ContactUs />}>
          <Route index element={<ContactUs />} />
        </Route>
        <Route path="/orders/:orderid/:orderToken" element={<QuickRedirect />}>
          <Route index element={<QuickRedirect />} />
        </Route>
        <Route path="/pay" element={<RedirectPay />}>
          <Route index element={<RedirectPay />} />
        </Route>
        <Route path="/processpayment" element={<SubWrapper />}>
          <Route index element={<SubWrapper />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
