import makeApiRequest from "../Utils/api";
import SabpaisaPaymentGateway from "./SabpaisaPaymentGateway";
import React, { useEffect, } from "react";

export default class SubWrapper extends React.Component {

    async updatePaymentStatus(values){
        // let res = await makeApiRequest(
        //     "POST",
        //     { orderId: orderId, paymentStatus: "Paid" },
        //     "/orders/update",
        //     setSnackSeverity,
        //     setOpenSnackbar,
        //     setOpenSnackbarContent,
        //     false
        //   );
        try {
            const requestOptions = {
              method: "POST",
              headers: {
                'Content-Type': 'application/json', // Adjust as needed
                // Add other headers if required
              },
              body:  JSON.stringify(values) ,
            };
            // console.log(JSON.stringify(values));
        
            const response = await fetch(process.env.REACT_APP_API_URL+"/orders/update", requestOptions);
            const data = await response.json();
            console.log(data);
            if (response.ok) {

                if(response.status === 200) {
                 if(data.message === "Order updated successfully") {
                    //success
                    alert("Payment Success");
                    
                    localStorage.removeItem("payValues")
                 } else if(data.message === "Products are out of Stock") {
                    alert("Out of stock")
                    localStorage.removeItem("payValues")
                 }
                  } else {
                    alert("error")
                  }
              
              
            } else {
                alert("error")
            }
          } catch (error) {
            alert("error")
            console.log(error);
           
          }
    }

    componentDidMount() {
        const obj = JSON.parse(localStorage.getItem("payValues")) ? JSON.parse(localStorage.getItem("payValues")) : {};
        // const modifiedObj = {
        //     orderId: obj["orderId"]
        // }

        // localStorage.setItem("payValues", JSON.stringify(modifiedObj));

        if (obj["name"] && obj["address"] && obj["amount"] && obj["email"]
            && obj["orderId"] && obj["phone"]
        ) {
            if(window.location.href.split("encResponse=").length> 1) {
 const clientCode = window.location.href.split("encResponse=")[1];
            console.log(clientCode);
            console.log(obj["orderId"]);
            var CryptoJS = require("crypto-js");
            let iv1 = CryptoJS.enc.Base64.parse("3NV2ztMRsOoOmgnr");

            var key = CryptoJS.enc.Utf8.parse("ADkVvLGQw7X5rRbJ");
            var cipherBytes = CryptoJS.enc.Base64.parse(clientCode + "==");

            var decrypted = CryptoJS.AES.decrypt({ ciphertext: cipherBytes }, key, {
                iv: iv1,
                mode: CryptoJS.mode.CBC,
                padding: CryptoJS.pad.Pkcs7
            });
            let status = decrypted.toString(CryptoJS.enc.Latin1).split("status=")[1].split("&")[0];
            console.log(status);
            if(status === "SUCCESS") {
                this.updatePaymentStatus({ orderId: obj["orderId"], paymentStatus: "Paid" });
            } else {
                console.log(status);
                this.updatePaymentStatus({ orderId: obj["orderId"], paymentStatus: "Failed", status: "Payment Failed"  });
            }
            }

        } else {
            console.log(
                obj
            );
            // const clientCode = window.location.href.split("?")[1].split("&")[1].split("encResponse=")[1];
            // console.log(clientCode);
            // console.log(obj["orderId"]);
            // var CryptoJS = require("crypto-js");
            // let iv1 = CryptoJS.enc.Base64.parse("3NV2ztMRsOoOmgnr");

            // var key = CryptoJS.enc.Utf8.parse("ADkVvLGQw7X5rRbJ");
            // var cipherBytes = CryptoJS.enc.Base64.parse(clientCode + "==");

            // var decrypted = CryptoJS.AES.decrypt({ ciphertext: cipherBytes }, key, {
            //     iv: iv1,
            //     mode: CryptoJS.mode.CBC,
            //     padding: CryptoJS.pad.Pkcs7
            // });
            // let status = decrypted.toString(CryptoJS.enc.Latin1).split("status=")[1].split("&")[0];
            // console.log(status);
            // if(status === "SUCCESS") {
            //     this.updatePaymentStatus({ orderId: obj["orderId"], paymentStatus: "Paid" });
            // }
        }
        


    }

    render() {
        // const params = window.location.href;
        // let itemArray = params.split('?').slice(1, params.length - 1);
        // const obj = itemArray.reduce((acc, item) => {
        //     const [key, value] = item.split("=");
        //     acc[key] = value;
        //     return acc;
        // }, {});
        // console.log(obj);
        const obj = JSON.parse(localStorage.getItem("payValues")) ? JSON.parse(localStorage.getItem("payValues")) : {};


        if (obj["name"] && obj["address"] && obj["amount"] && obj["email"]
            && obj["orderId"] && obj["phone"]
        ) {
            if(window.location.href.split("encResponse=").length> 1) {
                window.top.close();

                return (
                    <>
                        Payment success. You can close this window now
                    </>
                )
            } else {
                return (
                    <>
    
                        <div id='renderSabPaisa'></div>
                        <SabpaisaPaymentGateway name={obj["name"]}
                            address={obj["address"]}
                            phone={obj["phone"]} amount={obj["amount"]} isOpen={true}
                            email={obj["email"]}
                        />
                    </>
                )
            }
            // localStorage.setItem("payValues", JSON.stringify(modifiedObj));
           
        } else {

            //         return decrypted.toString(CryptoJS.enc.Utf8);
            //   }
            return (
                <>
                    You can now close this window</>
            )
        }

    }
}
// useEffect(() => {
//     const btn = document.getElementById('renderSabPaisa');
//     btn.click();
// }, [])

