import "./ProductContent.css";
import { useContext, useState } from "react";
import { LayoutContext, addressAPI, cartAPI, ordersAPI } from "../ShopLayout";
import { useSpring, animated, update } from "@react-spring/web";
import { ProductCartButtonSm } from "../../Shared/ProductCartButtonSm";
import { ProductCartButtonMd } from "../../Shared/ProductCartButtonMd";
import makeApiRequest from "../../Utils/api";
import { signInWithGooglePopup } from "../../Utils/firebase.utils";
import { logGoogleUser } from "../../Nav/NavHome";
import { AppContext } from "../../../App";
import { adjustCart } from "../../Utils/appUtils";
import LockIcon from '@mui/icons-material/Lock';

export const addToCartAPI = async ({
  recalled = false,
  setSaving,
  setCart,
  setSubPage,
  setSidePage,
  setSnackSeverity,
  setOpenSnackbar,
  setOpenSnackbarContent,
  cart,
  selectedProduct,
  setUserDetails,
  setIsUserLoggedIn,
  fromCart = false,
  operator,
  reload = false,
  isPlacedOrder = false,
  setAddress,
  setOrders,
  reOrder = false,
  payload = [],
}) => {
  const userDetails = JSON.parse(localStorage.getItem("user"));
  setSaving(true);
  console.log("selected", selectedProduct);
  if(selectedProduct) {
    if (userDetails?.email) {
      let updatedLocalCart = cart;
      if (recalled) {
        let res = await makeApiRequest(
          "POST",
          { userId: userDetails?.email },
          "/cart/get",
          setSnackSeverity,
          setOpenSnackbar,
          setOpenSnackbarContent,
          false
        );
        if (res) {
          if (res.status === 200) {
            updatedLocalCart = res?.cart?.cart;
          } else {
            updatedLocalCart = [];
          }
        }
      }
  
      if (!isPlacedOrder) {
        const currentProductIndex = updatedLocalCart.findIndex(
          (c) => c.productId === selectedProduct.productId
        );
        if (currentProductIndex !== -1) {
          if (operator === "+") {
            updatedLocalCart[currentProductIndex].quantity =
              parseInt(updatedLocalCart[currentProductIndex].quantity) + 1;
          } else {
            if (
              parseInt(updatedLocalCart[currentProductIndex].quantity) - 1 ===
              0
            ) {
              updatedLocalCart.splice(currentProductIndex, 1);
            } else {
              updatedLocalCart[currentProductIndex].quantity =
                parseInt(updatedLocalCart[currentProductIndex].quantity) - 1;
            }
          }
        } else {
          if (reOrder) {
            updatedLocalCart = payload;
          } else {
            updatedLocalCart.push({
              id: Date.now() + Math.random().toFixed(),
              productId: selectedProduct.productId,
              quantity: 1,
            });
          }
        }
      } else {
        updatedLocalCart = [];
      }
  
      makeApiRequest(
        "POST",
        {
          userId: userDetails?.email,
          cart: updatedLocalCart,
        },
        "/cart/add",
        setSnackSeverity,
        setOpenSnackbar,
        setOpenSnackbarContent,
        false
      )
        .then((res) => {
          if (res?.status === 200) {
            if (res?.cart) {
              setCart(res?.cart);
              setSaving(false);
              if (!fromCart) {
                if (reOrder) {
                  setSidePage("Menu");
                  setSubPage("address");
                } else {
                  setSidePage("Menu");
                  setSubPage("cart");
                }
              }
            }
          } else if (res?.status === 201) {
            setCart(res?.cart);
            setSnackSeverity("warning");
            setOpenSnackbar(true);
            setOpenSnackbarContent("Some items are adjusted as per availability");
            setSaving(false);
            if (!fromCart) {
              setSidePage("Menu");
              setSubPage("cart");
            }
          } else {
            setSaving(false);
          }
        })
        .catch((e) => {
          setSnackSeverity("error");
          setOpenSnackbar(true);
          setOpenSnackbarContent("Error adding to cart. Please try again later");
          setSaving(false);
        });
    } else {
      let response = await logGoogleUser(
        setUserDetails,
        setIsUserLoggedIn,
        setOpenSnackbar,
        setOpenSnackbarContent,
        setSnackSeverity,
        false
      );
      if (response) {
        await cartAPI(
          setSnackSeverity,
          setOpenSnackbar,
          setOpenSnackbarContent,
          setCart
        );
        await addressAPI(
          setSnackSeverity,
          setOpenSnackbar,
          setOpenSnackbarContent,
          setAddress
        );
        await ordersAPI(
          setSnackSeverity,
          setOpenSnackbar,
          setOpenSnackbarContent,
          setOrders
        );
  
        await addToCartAPI({
          recalled: true,
          cart: cart,
          selectedProduct: selectedProduct,
          setCart: setCart,
          setIsUserLoggedIn: setIsUserLoggedIn,
          setOpenSnackbar: setOpenSnackbar,
          setOpenSnackbarContent: setOpenSnackbarContent,
          setSaving: setSaving,
          setSidePage: setSidePage,
          setSnackSeverity: setSnackSeverity,
          setSubPage: setSubPage,
          setUserDetails: setUserDetails,
          fromCart: false,
          operator: "+",
          setAddress: setAddress,
          setOrders: setOrders,
        });
      } else {
        setSaving(false);
      }
    }
  } else {
    setSnackSeverity("warning");
    setOpenSnackbarContent("Products are not in stock");
    setOpenSnackbar(true);
  }
 
};
export function ProductContent() {
  const {
    width,
    setSubPage,
    setSidePage,
    selectedProduct,
    cart,
    setCart,
    setSnackSeverity,
    setOpenSnackbar,
    setOpenSnackbarContent,
    products,
    setAddress,
    setOrders,
  } = useContext(LayoutContext);
  const { isUserLoggedIn, setIsUserLoggedIn, setUserDetails } =
    useContext(AppContext);
  const [saving, setSaving] = useState(false);

  // const [listProducts, setListProducts] = useState(dummyProducts.products);

  // useEffect(() => {
  //   console.log(dummyProducts.products.filter((v) => v.categories.includes(selectedCategory)));
  //   setListProducts(dummyProducts.products.filter((v) => v.categories.includes(selectedCategory)))

  // }, [selectedCategory])
  console.log(selectedProduct.stock);

  return (
    <>
      <animated.div
        className={"productDescContent"}
        style={{
          //   backgroundColor: "red",
          width: width > 570 ? "60vw" : "100vw",
          display: "inline-flex",
          paddingLeft: "7vw",
          marginTop: 30,
          height: width > 570 ? "80vh" : "30vh",
          position: width > 570 ? "fixed" : null,
          //   ...springs,
        }}
      >

        <div>
          <div className="productName">
            <span>{`${selectedProduct.name} ${selectedProduct.size}`}</span>
          </div>
          <div className="productPrice">
            <span>&#x20B9;{`${selectedProduct.price}`}</span>
          </div>
          <div className="productDesc">
            <span>{`${selectedProduct.desc}`}</span>
          </div>
          <div
            style={{
              // backgroundColor: "red",
              textAlign: "center",
              marginTop: 40,
              marginLeft: width < 570 && -20,
              width: width < 570 && "100vw",
            }}
          >
            <ProductCartButtonSm
              action={() => {
                if(parseInt(selectedProduct.stock) !== 0) {
                  const updatedCart = adjustCart({
                    cart: cart,
                    products: products,
                  });
                  setCart(updatedCart);
                  if (!saving) {
                    addToCartAPI({
                      recalled: false,
                      cart: updatedCart,
                      selectedProduct: selectedProduct,
                      setCart: setCart,
                      setIsUserLoggedIn: setIsUserLoggedIn,
                      setOpenSnackbar: setOpenSnackbar,
                      setOpenSnackbarContent: setOpenSnackbarContent,
                      setSaving: setSaving,
                      setSidePage: setSidePage,
                      setSnackSeverity: setSnackSeverity,
                      setSubPage: setSubPage,
                      setUserDetails: setUserDetails,
                      fromCart: false,
                      operator: "+",
                      setAddress: setAddress,
                      setOrders: setOrders,
                      isPlacedOrder: false,
                      reload: false,
                    });
                  }
                }

              }}
              content={saving ? "adding..." : "Add to Cart"}
              mode={parseInt(selectedProduct.stock) !== 0 ? "active" : "not-active"}
              style={
                {
                  // borderRadius: 100,
                }
              }

              icon={parseInt(selectedProduct.stock) === 0 && <LockIcon style={{height: 20}} />}
            />
            {parseInt(selectedProduct.stock) !== 0 &&
              parseInt(selectedProduct.stock) <= 10 && (
                <div className="hurry-content"><span>{`Hurry! only ${selectedProduct.stock} items left`}</span></div>
              )}
              {parseInt(selectedProduct.stock) === 0 && (
                <div className="hurry-content"><span>{`This product is currently out of stock!`}</span></div>
              )}
          </div>
        </div>
      </animated.div>
    </>
  );
}
